import { Cursors, PaperToolConfig } from '../../../../../types'
import Measure from '../measure/Measure.tool'

/**
 * Calibrate.tool.tsx
 * Used to set a calibration factor between 2 points on a plan
 */
export class Calibrate extends Measure {
    static NAME = 'CALIBRATE'
    static CURSOR = Cursors.CROSSHAIR

    private static readonly CROSS_HAIR_HIT_BOX_IN_PX = 5
    private path: paper.Path | null = null
    private points: paper.Group[] = []
    private selectedCrossHair: paper.Group | null = null

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Calibrate.NAME
        this.cursor = Calibrate.CURSOR
        this.path = null
    }

    private measurementPointHitTest = (point1: paper.Point, point2: paper.Point): boolean => {
        return (
            Math.abs(point1.x - point2.x) < Calibrate.CROSS_HAIR_HIT_BOX_IN_PX &&
            Math.abs(point1.y - point2.y) < Calibrate.CROSS_HAIR_HIT_BOX_IN_PX
        )
    }

    cancel = () => {
        this.path?.remove()
        this.points.forEach((point: paper.Group) => {
            point.remove()
        })

        this.mediator.mediate('tools', { measurement: null, calibrationLineLength: null })
        this.mediator.mediate('common', {
            cursor: this.cursor,
            tooltip: { title: '', visible: false, color: '#000000' },
        })

        this.path = null
        this.points = []
    }

    onMouseDrag = (event: paper.ToolEvent): void => {
        if (this.toolPanning(event)) return

        if (this.selectedCrossHair) {
            const newPosition = this.selectedCrossHair.position.add(event.delta)

            if (this.selectedCrossHair.data.pathSegment) {
                const selectedSegment = this.selectedCrossHair.data.pathSegment

                if (selectedSegment) {
                    selectedSegment.point = newPosition
                }
            }

            this.selectedCrossHair.position = newPosition
        }
    }

    onMouseMove = (event: paper.ToolEvent) => {
        const hitTargets = this.points
            ? this.points.filter((crosshair) => this.measurementPointHitTest(crosshair.position, event.point))
            : null

        this.mediator.mediate('common', { cursor: hitTargets && hitTargets.length > 0 ? Cursors.MOVE : this.cursor })
    }

    onMouseUp = (): void => {
        // resets the cursor in case panning was done
        this.mediator.mediate('common', { cursor: Measure.CURSOR })

        if (this.selectedCrossHair) {
            this.selectedCrossHair = null
        }

        if (this.path && this.path.length > 0) {
            const coordinates = this.path.segments.map((segment) => [segment.point.x, segment.point.y])

            this.mediator.mediate('tools', {
                calibrationLineLength: this.path.length,
                calibrationLineCoords: coordinates,
            })
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        // Force calibration angle
        const newPoint =
            this.points.length === 1 ? this.calculateFixedAnglePoint(this.points[0].position, event.point) : event.point

        const hitTargets = this.points
            ? this.points.filter((crosshair) => this.measurementPointHitTest(crosshair.position, newPoint))
            : null

        if (hitTargets && hitTargets.length > 0) {
            this.selectedCrossHair = hitTargets[0]
            return
        }

        // if there are already 2 points, clear the path
        if (this.path?.segments.length === 2) {
            this.cancel()
            return
        }

        // create a new segment if segment doesn't exist
        if (!this.path) {
            this.path = new this.paper.Path()
            this.path.strokeColor = new this.paper.Color(this.measureStrokeColor)
            this.path.strokeWidth = this.measureStrokeWidth
            this.path.strokeScaling = false
            this.path.locked = true

            const scaleFactor = this.setScaleFromPointClick(newPoint)
            if (scaleFactor) {
                this.scaleFactor = scaleFactor
            }
        }

        const crossHair = this.constructCrosshairMarker(newPoint)

        crossHair.data.pathSegment = this.path.add(crossHair.position)

        this.points.push(crossHair)
    }
}

export default Calibrate
