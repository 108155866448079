import { useCallback, useMemo, useState } from 'react'
import { addBuilding } from '../actions/drawable'
import { createBlankBuilding } from '../api/takeoff-api'
import { useAppDispatch, useAppSelector } from '../hooks'
import {
    DocumentChunkWBuildingID,
    moveChunkToBuilding,
    selectDocumentChunksWBuildingID,
    selectDocumentMappings,
} from '../imup/slices/documents'
import { updateGeometriesRcmBuildingId } from '../imup/slices/geometry'
import { DocumentChunk } from '../models/documentChunk'
import { Project } from '../models/project'
import { Building } from '../models/projectBuilding'
import { getProject, getProjectBuildings } from '../reducers/drawable'

export function useBuildingGroupings() {
    const dispatch = useAppDispatch()

    const documentChunks = useAppSelector(selectDocumentChunksWBuildingID)
    const documentMappings = useAppSelector(selectDocumentMappings)
    const buildings: Building[] = useAppSelector((state) => getProjectBuildings(state.drawable))
    const project: Project = useAppSelector((state) => getProject(state.drawable))

    const [newGroupName, setNewGroupName] = useState<string>('')
    const [createGroupModalVisible, setCreateGroupModalVisible] = useState<boolean>(false)

    const handleToggleSetCreateGroupModal = useCallback(() => {
        setCreateGroupModalVisible((prevState) => !prevState)
    }, [])

    const buildingGroupings = useMemo(() => {
        if (!documentChunks || documentChunks.length === 0) {
            return null
        }

        let groupings: Record<any, DocumentChunkWBuildingID[]>
        if (buildings && documentMappings && documentChunks) {
            // A document mapping is linked to a chunk 1-1. Document mappings have the building id by which we want to group chunks.
            // If a document mapping does not exist for a given chunk, that chunk is ungrouped by default.
            // Otherwise, we want to group that chunk by the building id that is listed on that chunk's document mapping.
            groupings = { [-1]: [] as DocumentChunkWBuildingID[] }

            // -2 used to keep deleted pages
            groupings[-2] = []
            for (const building of buildings) {
                groupings[building.id] = []
            }

            for (const chunk of documentChunks) {
                // Find the mapping for the current chunk
                const mapping = documentMappings.find((mapping) => mapping.document_chunk_id === chunk.id)
                if (chunk.is_user_deleted) {
                    groupings[-2].push(chunk)
                } else if (mapping || chunk.buildingID) {
                    // If it exists, add it to the group for its building (and create a group for this building if that doesn't exist)
                    // If there's no mapping, default to using the chunk's normalized building id in the case we want to group it before we created a mapping
                    const buildingId = mapping?.building_id ?? chunk.buildingID ?? -1
                    if (groupings[buildingId]) {
                        groupings[buildingId].push(chunk)
                    } else {
                        groupings[buildingId] = [chunk]
                    }
                } else {
                    // Otherwise, leave it ungrouped
                    groupings[-1].push(chunk)
                }
            }
        } else {
            groupings = { [-1]: documentChunks ?? [] } // Everything is ungrouped if there are no buildings
        }
        return groupings
    }, [buildings, documentChunks, documentMappings])

    const moveDocumentToGroup = (documentChunk: DocumentChunk, toBuildingId: number) => {
        const foundBuilding = buildings.find((building) => building.id === toBuildingId)

        dispatch(moveChunkToBuilding({ toBuildingId, documentChunk }))

        if (foundBuilding) {
            dispatch(
                updateGeometriesRcmBuildingId({
                    rcmBuildingId: foundBuilding.rcm_id,
                    documentChunkId: documentChunk.id,
                })
            )
        }
    }

    const saveNewGroup = (): void => {
        createBlankBuilding(project.id, newGroupName).then((res) => {
            const responseBuilding = res[0]
            const newBuilding: Building = {
                id: responseBuilding.id,
                name: responseBuilding.name,
                project_id: responseBuilding.project_id,
                rcm_id: responseBuilding.rcm_id,
                settings: responseBuilding.settings,
                updated_at: new Date().toLocaleDateString(),
            }
            dispatch(addBuilding(newBuilding))
            setCreateGroupModalVisible(false)
            setNewGroupName('')
        })
    }

    return {
        buildingGroupings,
        createGroupModalVisible,
        handleToggleSetCreateGroupModal,
        moveDocumentToGroup,
        saveNewGroup,
        setNewGroupName,
    }
}
