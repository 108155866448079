import { AbstractMesh, Axis, Node as BabylonNode, BoundingInfo, Mesh, Tools, Vector3 } from '@babylonjs/core'
import { createAction, createSelector } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { all, call, CallEffect, fork, put, select, StrictEffect, takeLatest } from 'redux-saga/effects'
import { RootState } from '../../../stores'
import managers from '../../lib/managers'
import { BabylonManager } from '../../lib/managers/BabylonManager'
import { CameraTool, CustomMaterial, CustomMesh, Line, Polygon, Select, Workspace } from '../../lib/toolBoxes/3D'
import { fetchModelStructureFailure, State3D, updateTranslationInfo } from '../../slices/3D'
import { sceneRendered } from '../../slices/loading'
import {
    BUSH_PARTIAL_MATERIAL_NAME,
    CornersMeshInputData,
    EDGE_TUBE_RADIUS,
    EDGE_TUBE_RADIUS_LARGE,
    IBabylonLineWithMetadata,
    IBabylonPolygonContourWithMetadata,
    IGeometry,
    IGeometryMetadata,
    IModelTranslationInfo,
    MaterialOptionsType,
    SHRUB_PARTIAL_MATERIAL_NAME,
} from '../../types'
import SagaActionEnabledError from '../utils/sagaBaseError'
import { ModelType } from './../../types'
import { hideDefaultHiddenDrawables } from './handleToggleDrawableGroups'
import { selectionPredicateGenerator } from './selectMesh'

export const build3DModel = createAction('build3DModel')

export const Select3DInputData = createSelector(
    (state: RootState) => state.IMUP['3D'],
    ({
        structure,
        geometries,
        roofFacesData,
        overFramedRoofFacesData,
        cornersData,
        roofSingleEdgesData,
        junctionsData,
    }) => ({
        structure,
        geometries,
        roofFacesData,
        overFramedRoofFacesData,
        cornersData,
        roofSingleEdgesData,
        junctionsData,
    })
)

export const calculateMeshVisibility = (metaData: IGeometryMetadata): number => {
    return metaData.isReflectedInTwoD ? 1 : CustomMesh.REDUCED_VISIBILITY
}

/**
 * Returns the radius that will be used when creating Line meshes (tubes)
 * @param {ModelType} modelType the model type
 * @returns {number} the radius to be used for the line
 */
export const calculateLineRadius = (modelType: ModelType): number => {
    switch (modelType) {
        case ModelType.CORNER:
            return EDGE_TUBE_RADIUS
        case ModelType.GABLE:
        case ModelType.FLASHING:
        case ModelType.EAVE:
        case ModelType.JUNCTION:
            return EDGE_TUBE_RADIUS_LARGE
        default:
            return EDGE_TUBE_RADIUS
    }
}

export const calculateMeshTranslationsToCenter = (modelBoundingInfo: BoundingInfo): IModelTranslationInfo => {
    const xTranslation = -(
        (modelBoundingInfo.maximum.x - modelBoundingInfo.minimum.x) / 2 +
        modelBoundingInfo.minimum.x
    )
    const yTranslation = -(
        (modelBoundingInfo.maximum.y - modelBoundingInfo.minimum.y) / 2 +
        modelBoundingInfo.minimum.y
    )
    const zTranslation = -(
        (modelBoundingInfo.maximum.z - modelBoundingInfo.minimum.z) / 2 +
        modelBoundingInfo.minimum.z
    )

    return {
        x: xTranslation,
        y: yTranslation,
        z: zTranslation,
    }
}

type Bounds = { min: Vector3; max: Vector3 }

/**
 * Compute the bounding box info for a node
 *
 * @param {BabylonNode} node Node is the basic class for all scene objects (Mesh, Light Camera)
 */
export function* computeBounds(
    node: BabylonNode
): Generator<CallEffect<BabylonNode[]> | Bounds, BoundingInfo, AbstractMesh[] & Bounds> {
    const descendants: Array<AbstractMesh> = yield call(node.getChildMeshes.bind(node))
    const { min, max }: Bounds = yield descendants.reduce(
        ({ min, max }, descendant) => {
            const bounds = descendant.getBoundingInfo()

            if (bounds.minimum.x + descendant.position.x < min.x) {
                min.x = bounds.minimum.x + descendant.position.x
            }

            if (bounds.minimum.y + descendant.position.y < min.y) {
                min.y = bounds.minimum.y + descendant.position.y
            }

            if (bounds.minimum.z + descendant.position.z < min.z) {
                min.z = bounds.minimum.z + descendant.position.z
            }

            if (max.x < bounds.maximum.x + descendant.position.x) {
                max.x = bounds.maximum.x + descendant.position.x
            }

            if (max.y < bounds.maximum.y + descendant.position.y) {
                max.y = bounds.maximum.y + descendant.position.y
            }

            if (max.z < bounds.maximum.z + descendant.position.z) {
                max.z = bounds.maximum.z + descendant.position.z
            }
            return { min, max }
        },
        {
            min: new Vector3(Infinity, Infinity, Infinity),
            max: new Vector3(-Infinity, -Infinity, -Infinity),
        }
    )

    return new BoundingInfo(min, max)
}

export function* translateModel(
    mesh: Mesh,
    translationsRequiredToCenter: IModelTranslationInfo
): Generator<StrictEffect, void, void> {
    // Rotate model from "laying" to "standing"
    yield call([mesh, 'rotate'], Axis.X, Tools.ToRadians(90))

    // Move model along X access to position relative to model root node
    yield call([mesh, 'translate'], Axis.X, translationsRequiredToCenter.x)

    // Move model along Y access to position relative to model root node
    yield call([mesh, 'translate'], Axis.Y, translationsRequiredToCenter.y)

    // Move model along Z access to position relative to model root node
    yield call([mesh, 'translate'], Axis.Z, translationsRequiredToCenter.z)

    // Bake the mesh with latest, transformed vertices
    yield call([mesh, 'bakeCurrentTransformIntoVertices'])
}

export function* centerModel(
    root: Mesh
): Generator<StrictEffect, void, BoundingInfo & IModelTranslationInfo & Array<AbstractMesh> & AbstractMesh> {
    // Compute the bounds for the root mesh
    const modelBoundingInfo: BoundingInfo = yield call(computeBounds, root)

    // Calculate the translations required to center a given mesh around the root node
    const translationsRequiredToCenter: IModelTranslationInfo = yield call(
        calculateMeshTranslationsToCenter,
        modelBoundingInfo
    )

    // Get al of the child meshes of the root node
    const meshesToTranslate: Array<AbstractMesh> = yield call(root.getChildMeshes.bind(root))

    // For all of the child meshes, translate their position about the center of the root node
    yield all(
        meshesToTranslate.map((abstractMesh) =>
            fork(translateModel, abstractMesh as Mesh, translationsRequiredToCenter)
        )
    )

    // Calculate new bounds for the root node after the child meshes have been translated.
    const bounds: BoundingInfo = yield call(computeBounds, root)

    // Apply the new bounding info to the root node
    yield call(root.setBoundingInfo.bind(root), bounds)

    yield call(root.freezeWorldMatrix.bind(root))
    root.doNotSyncBoundingInfo = true

    // Save translation info to use in updating meshes that are later added
    // to the model
    yield put(updateTranslationInfo(translationsRequiredToCenter))
}

export function* createMeshesWithGeometries(
    geometries: Record<string, IGeometry[]>
): Generator<StrictEffect, Array<Mesh>, BabylonManager & CustomMesh & Array<Mesh>> {
    const manager: BabylonManager | null = yield call(managers.get3DManager)

    if (isNull(manager)) return []

    const meshTool: CustomMesh = yield call(manager.getTool, CustomMesh.NAME)

    const meshes: Mesh[] = yield all(
        Object.keys(geometries).flatMap((key) => {
            const geometryEntries = geometries[key]
            return geometryEntries
                .filter(
                    (geometry) =>
                        !geometry.name ||
                        (geometry.name &&
                            !geometry.name.includes(SHRUB_PARTIAL_MATERIAL_NAME) &&
                            !geometry.name.includes(BUSH_PARTIAL_MATERIAL_NAME))
                )
                .map((geometry) => {
                    return call(
                        meshTool.create,
                        { vertices: geometry.vertices, indices: geometry.indices },
                        {
                            name: geometry.name || key,
                            id: key,
                            visibility: calculateMeshVisibility(geometry.metaData),
                            metadata: geometry.metaData,
                            isPickable: geometry.metaData ? !!geometry.metaData.isReflectedInTwoD : false,
                        }
                    )
                })
        })
    )

    yield fork(addMeshesToModel, meshes)
    return meshes
}

function* addMeshesToModel(meshes: Mesh[]) {
    try {
        const manager: BabylonManager | null = yield call(managers.get3DManager)
        if (isNull(manager)) return
        const workspace: Workspace = yield call(manager.getTool, Workspace.NAME)
        yield all(meshes.map((mesh) => call(workspace.addMeshToCorrectNode, mesh, manager.rootNode!)))
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* createPolygonMeshes(polygons: Record<string, IBabylonPolygonContourWithMetadata>) {
    const manager = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const polygon: Polygon = yield call(manager.getTool, Polygon.NAME)

    const meshes: Mesh[] = yield all(
        Object.keys(polygons).map((key) =>
            call(polygon.create, polygons[key].shape, {
                name: key,
                id: key,
                visibility: calculateMeshVisibility(polygons[key].metaData),
                metadata: polygons[key].metaData,
                isPickable: polygons[key].metaData ? !!polygons[key].metaData.isReflectedInTwoD : false,
            })
        )
    )

    yield fork(addMeshesToModel, meshes)

    return meshes
}

export function* createLineMeshes(
    lines: Record<string, IBabylonLineWithMetadata> | Record<string, CornersMeshInputData>
) {
    const manager = yield call(managers.get3DManager)

    if (isNull(manager)) return

    const [lineTool, workspace]: [Line, Workspace] = yield call(manager.getTools, [Line.NAME, Workspace.NAME])

    const meshes: Mesh[] = yield all(
        Object.keys(lines).map((key) => {
            const lineAsCorner: CornersMeshInputData = lines[key] as CornersMeshInputData
            let storeyName = lines[key].metaData.storeyName
            if (lineAsCorner.intersections) {
                const wallStoreyName = workspace.getMeshStoryName(lineAsCorner.intersections[0].wall1Id)
                if (wallStoreyName) {
                    storeyName = wallStoreyName
                }
            }
            return call(
                lineTool.create,
                lines[key].path,
                calculateLineRadius(lines[key].metaData.modelType as ModelType),
                {
                    name: key,
                    id: key,
                    visibility: calculateMeshVisibility(lines[key].metaData),
                    metadata: { ...lines[key].metaData, storeyName },
                    isPickable: lines[key].metaData ? !!lines[key].metaData.isReflectedInTwoD : false,
                }
            )
        })
    )

    yield fork(addMeshesToModel, meshes)

    return meshes
}

export function* build3DModelHome(
    initialize: boolean = true
): Generator<
    StrictEffect | boolean,
    void,
    BabylonManager &
        Pick<
            State3D,
            | 'structure'
            | 'geometries'
            | 'roofFacesData'
            | 'overFramedRoofFacesData'
            | 'cornersData'
            | 'roofSingleEdgesData'
            | 'junctionsData'
        > &
        CustomMaterial &
        Workspace
> {
    try {
        const manager: BabylonManager = yield call(managers.get3DManager)

        if (!manager) return

        // Get the structure and geometry data from the store
        const {
            structure,
            geometries,
            roofFacesData,
            overFramedRoofFacesData,
            cornersData,
            roofSingleEdgesData,
            junctionsData,
        }: Pick<
            State3D,
            | 'structure'
            | 'geometries'
            | 'roofFacesData'
            | 'overFramedRoofFacesData'
            | 'cornersData'
            | 'roofSingleEdgesData'
            | 'junctionsData'
        > = yield select(Select3DInputData)

        // Check if structure and geometries exist
        if (structure && geometries) {
            // initialize the 3D scene
            yield initialize && call(manager.init)

            // Get the custom material and custom mesh tools
            const [materialTool, workspaceTool, selectTool]: [CustomMaterial, Workspace, Select] = yield call(
                manager.getTools,
                [CustomMaterial.NAME, Workspace.NAME, Select.NAME]
            )

            // Load in the materials associated with the model structure
            yield call(materialTool.prepareGenerators, structure)

            // ************** Mesh building effects to be run in parallel ************************* //
            const meshBuildingProcedures: Array<StrictEffect> = []
            /**
             * For each of the geometries, create a mesh as a child to the root node of the scene.
             */
            meshBuildingProcedures.push(fork(createMeshesWithGeometries, geometries))
            /**
             * Polygon elements that need to be converted to meshes
             */
            roofFacesData && meshBuildingProcedures.push(fork(createPolygonMeshes, roofFacesData))
            overFramedRoofFacesData && meshBuildingProcedures.push(fork(createPolygonMeshes, overFramedRoofFacesData))
            /**
             * Line elements that need to be converted to meshes
             */
            cornersData && meshBuildingProcedures.push(fork(createLineMeshes, cornersData))
            roofSingleEdgesData && meshBuildingProcedures.push(fork(createLineMeshes, roofSingleEdgesData))
            junctionsData && meshBuildingProcedures.push(fork(createLineMeshes, junctionsData))
            // ************************************************************************************* //

            // build all meshes in parallel
            yield all(meshBuildingProcedures)

            // apply mesh transformation procedures in parallel
            yield all([
                call(selectTool.setSelectionPredicate, selectionPredicateGenerator(null)),
                // Apply the materials to the meshes in the scene
                call(materialTool.applyMaterialOptionsToMeshes, MaterialOptionsType.DRAWABLE_COLORS),
                // Start the task of centering the model around the root node , which should necessarily exist after manager has initialized the scene
                fork(centerModel, manager.rootNode!),
                // Activate the Select and Camera tools for the end user
                call(manager.useTools, [Workspace.NAME, CameraTool.NAME, Select.NAME]),
                //Hide drawables that are hidden by default
                call(hideDefaultHiddenDrawables, workspaceTool),
            ])

            // Wait for the scene to be ready (async)
            yield call(manager.onReady)

            // Dispatch event that model building side effect has completed
            yield put(sceneRendered())
        } else {
            // Throw an error if the geometry or structures are not available to build the model.
            throw new SagaActionEnabledError(
                fetchModelStructureFailure,
                'Build 3D Model failed. No structure or geometry available to construct 3D model.'
            )
        }
    } catch (error) {
        if ((error as any).actionToCall) {
            yield all([put((error as any).actionToCall((error as any).message))])
        }
    }
}

export function* watchForBuild3DModel() {
    yield takeLatest(build3DModel.type, build3DModelHome)
}
