import { PayloadAction } from '@reduxjs/toolkit'
import { all, call, fork, put, select, StrictEffect } from 'redux-saga/effects'
import { OpeningGroup } from '../../../models/activeDrawable'
import { NEW_IMUP_JOIST_TYPES_GROUP } from '../../../shared/constants/drawable-types'
import { JOIST_DIRECTION } from '../../../shared/constants/joist-directions'
import { isNonAreaJoistLine } from '../../../utils/project/project-helper-functions'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select, Workspace } from '../../lib/toolBoxes/2D'
import { setJoistDirection } from '../../slices/2D'
import { activateTool, selectSnappingEnabled } from '../../slices/tools'
import { getHiddenAndVisibleGroups } from '../utils/getHiddenAndVisibleGroups'
import { handleToggleActiveDrawable2D } from './handleToggleActiveDrawable'

export function* handleSetActiveDrawableGroup2D({
    payload,
}: PayloadAction<{ activeDrawableGroup: OpeningGroup; activeDrawableId: number | undefined }>): Generator<
    StrictEffect,
    void,
    (PaperManager | null) &
        Workspace &
        Select &
        OpeningGroup & { hiddenDrawableGroupIds: number[] } & paper.Item[] &
        paper.Item
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const { id: activeDrawableGroupId, type } = payload.activeDrawableGroup

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const isSnappingEnabled = yield select(selectSnappingEnabled)

        // Show only this drawable group when we activate it
        // If there is no active drawable group, we reset all shapes back to normal
        if (!activeDrawableGroupId) {
            const selectTool: Select = yield call(manager.getTool, Select.NAME)
            yield call(selectTool.removeHandles)
            yield call(workspaceTool.showAllDrawables)
            if (payload.activeDrawableId) yield fork(handleToggleActiveDrawable2D, payload.activeDrawableId)

            // We still need to hide groups that were set to hidden
            const { hiddenDrawableGroupIds } = yield call(getHiddenAndVisibleGroups)

            const visibilityTest = (item: paper.Item): boolean =>
                !hiddenDrawableGroupIds.includes(item.data.opening_group_id) && !isNonAreaJoistLine(item)

            const allPaperDrawables: paper.Item[] = yield call(
                workspaceTool.getItemsWithCriteria,
                'data',
                function (data: any) {
                    return data?.drawable_id || data?.aiSuggestion?.id
                }
            )

            yield all(
                allPaperDrawables.map((item) =>
                    call(
                        (item: paper.Item, visTest: (item: paper.Item) => boolean) => {
                            item.visible = visTest(item)
                        },
                        item,
                        visibilityTest
                    )
                )
            )

            // Select should be the active tool if we hit the cancel button
            yield put({ type: activateTool.type, payload: Select.NAME })
        } else {
            const visibilityTest = (item: paper.Item): boolean => {
                // keep all elements visible on page when snapping is active and items are visible
                if (isSnappingEnabled && item.visible) return true

                const isAISuggestion = item.data?.aiSuggestion?.id !== undefined

                if (isAISuggestion) {
                    // Keep all suggestions of the same type and drawables with the same type
                    return type === item.data.aiSuggestion.type
                }

                // Keep all with the same opening group id or AI suggestion type
                return activeDrawableGroupId === item.data?.opening_group_id
            }

            yield call(workspaceTool.hideDrawablesWithCondition, visibilityTest)
            if (payload.activeDrawableId) yield fork(handleToggleActiveDrawable2D, payload.activeDrawableId)

            if (NEW_IMUP_JOIST_TYPES_GROUP.includes(payload.activeDrawableGroup.type)) {
                yield put(
                    setJoistDirection(
                        payload.activeDrawableGroup.settings.direction
                            ? (payload.activeDrawableGroup.settings.direction.toLowerCase() as JOIST_DIRECTION)
                            : null
                    )
                )
            }

            for (const drawable of payload.activeDrawableGroup.openings) {
                const item = yield call(workspaceTool.getItemWithDrawableId, drawable.id)
                if (item) {
                    const colorAlpha = drawable.isActive ? item.data.activeOpacityValue : item.data.inactiveOpacityValue
                    if (item.fillColor) item.fillColor.alpha = colorAlpha
                    if (item.strokeColor) item.strokeColor.alpha = colorAlpha
                }
            }
        }
    } catch (error) {
        yield call(console.error, error)
    }
}
