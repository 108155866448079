import { FormValidation, UiSchema } from '@rjsf/core'
import { calculateLFMeasurementOfMaterial } from '../imup/sagas/2D/createDrawableLocation'
import { FormProperties } from '../imup/types'
import { ActiveDrawable } from '../models/activeDrawable'
import { GeneralDrawableSettings } from '../models/activeDrawableSettings'
import { DRAWABLE_TYPES } from '../shared/constants/drawable-types'
import { FORM_ERROR_MESSAGES } from '../shared/constants/error-messages'
import { pitchNumberValidation } from '../shared/services/field-validation/field-validation-service'
import { eaveGableLengthFormSubmitCleanUp } from '../shared/services/form-schema/eave-gable-length'

const otherValue = 'other'

const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
        'ui:widget': 'hidden',
    },
    name: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    cornice_crown_molding: {
        'ui:title': 'Has Cornice Crown Molding?',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    non_vented_gable: {
        'ui:title': 'Has Non-Vented Gable?',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    is_treated: {
        'ui:title': 'Treated',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    width: {
        'ui:placeholder': 'Select Gable Width (In)',
    },
    width_other: {
        'ui:placeholder': 'Other Gable Width (In)',
    },
    fascia_type: {
        'ui:placeholder': 'Select Fascia Type 1',
    },
    fascia_type_2: {
        'ui:placeholder': 'Select Fascia Type 2',
    },
    fascia_thickness_1: {
        'ui:placeholder': 'Select Fascia Thickness 1',
    },
    fascia_thickness_2: {
        'ui:placeholder': 'Select Fascia Thickness 2',
    },
    fascia_thickness: {
        'ui:placeholder': 'Select Fascia Thickness',
    },
    ladder_framing: {
        'ui:placeholder': 'None',
    },
    bracing: {
        'ui:placeholder': 'None',
    },
    frieze_type: {
        'ui:placeholder': 'Select Frieze Type 1',
    },
    frieze_type_2: {
        'ui:placeholder': 'Select Frieze Type 2',
    },
    frieze_thickness_1: {
        'ui:placeholder': 'Select Frieze Thickness 1',
    },
    frieze_thickness_2: {
        'ui:placeholder': 'Select Frieze Thickness 2',
    },
    folder: {
        'ui:readonly': true,
        'ui:widget': 'hidden',
    },
    fascia_texture: {
        'ui:placeholder': 'None',
    },
    fascia_texture_2: {
        'ui:placeholder': 'None',
    },
    frieze_texture: {
        'ui:placeholder': 'None',
    },
    frieze_texture_2: {
        'ui:placeholder': 'None',
    },
    fascia_woodtone_texture: {
        'ui:placeholder': 'None',
    },
    frieze_woodtone_texture: {
        'ui:placeholder': 'None',
    },
    pitch: {
        'ui:placeholder': 'Select Pitch',
    },
    pitch_other: {
        'ui:title': 'Pitch Other',
        'ui:placeholder': 'Pitch Other',
    },
    selection: {
        'ui:placeholder': 'Select Roofing',
    },
    fascia_width_1: {
        'ui:placeholder': 'None',
    },
    fascia_width_2: {
        'ui:placeholder': 'None',
    },
    frieze_width_1: {
        'ui:placeholder': 'None',
    },
    frieze_width_2: {
        'ui:placeholder': 'None',
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    non_vented_soffit: {
        'ui:title': 'Non-Vented Gable',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    soffit_type: {
        'ui:placeholder': 'Select Soffit Type',
    },
    soffit_vent_type: {
        'ui:placeholder': 'Select Soffit Vent Type',
    },
    soffit_tg_width: {
        'ui:placeholder': 'Select Soffit TG Width',
    },
    robust_rafter_tail: {
        'ui:title': 'Rafter Tail',
        'ui:field': 'SwitchField',
    },
    rafter_tail_size: {
        'ui:placeholder': 'Select Rafter Tail Size',
    },
    'ui:order': [
        'folder',
        'name',
        'label',
        'selection',
        'robust_rafter_tail',
        'rafter_tail_size',
        'rafter_tail_length',
        'rafter_tail_spacing',
        'soffit_type',
        'soffit_vent_type',
        'soffit_tg_width',
        'location',
        'width',
        'width_other',
        'fascia_type',
        'fascia_texture',
        'fascia_thickness_1',
        'fascia_width_1',
        'fascia_type_2',
        'fascia_texture_2',
        'fascia_thickness_2',
        'fascia_width_2',
        'frieze_type',
        'frieze_texture',
        'frieze_thickness_1',
        'frieze_width_1',
        'frieze_type_2',
        'frieze_texture_2',
        'frieze_thickness_2',
        'frieze_width_2',
        'pitch',
        'pitch_other',
        'cornice_crown_molding',
        'non_vented_gable',
        'is_treated',
    ],
}

const onValidate = (formData: any, errors: FormValidation) => {
    const pitchFields = ['pitch']
    pitchFields.forEach((pitchName) => {
        if (formData[pitchName] === otherValue) {
            const pitchOtherFieldName = `${pitchName}_other`
            if (!pitchNumberValidation(formData[pitchOtherFieldName])) {
                errors[pitchOtherFieldName].addError(FORM_ERROR_MESSAGES.PITCH_NUMBER)
            }
        }
    })
}

/**
 * Augment the drawables of the from based on the new settings
 * @returns
 * @param input
 */
function prepareDrawables(input: {
    drawables: ActiveDrawable[]
    newSettings: GeneralDrawableSettings
    scaleFactor: number
}): ActiveDrawable[] {
    const { drawables, newSettings } = input
    return drawables.map((drawable) => {
        drawable.settings.quantity = calculateLFMeasurementOfMaterial(drawable.settings.linear_total, {
            type: DRAWABLE_TYPES.GABLE_LENGTH,
            settings: newSettings,
        })
        return drawable
    })
}

const onSubmit = (settings, formData) => {
    const newSettings = eaveGableLengthFormSubmitCleanUp({ ...settings, ...formData })

    if (newSettings.pitch === otherValue && newSettings.pitch_other) {
        newSettings.pitch = String(newSettings.pitch_other).toUpperCase()

        delete newSettings.pitch_other
    } else if (newSettings.pitch !== otherValue) {
        newSettings.pitch_other = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit, prepareDrawables }

export default form
