import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import ModalDialogComponent from '../../../shared/components/modal-dialog/modal-dialog'
import { CLASSIFICATION_MESSAGES } from '../../../shared/constants/error-messages'
import { DPI_72 } from '../../../utils/calculations/scaleConversion/scaleConversion'
import managers from '../../lib/managers'
import { Select } from '../../lib/toolBoxes/2D'
import { saveUpdatedCalibrationFactorAction } from '../../sagas/2D/saveUpdatedCalibrationFactor'
import { selectScaleFactor } from '../../slices/2D'
import { selectActiveDocumentChunk } from '../../slices/documents'
import { selectCalibrationLineCoords, selectCalibrationLineLength } from '../../slices/tools'
import MeasurementContainer from './MeasurementContainer.component'
import './CalibrationSetModal.styles.scss'

// TODO: after resize it's always false - issue
// Define calibration line direction
export const isVertical = (x1: number, x2: number) => {
    return Math.round(x1) === Math.round(x2)
}

const CalibrationSetModal: React.FC = (): JSX.Element => {
    const dispatch = useAppDispatch()
    const [expectedFeet, setExpectedFeet] = useState<number | null>(null)
    const [expectedInches, setExpectedInches] = useState<number | null>(null)
    const [errorModalVisible, setErrorModalVisible] = useState(false)

    const manager = managers.get2DManager()

    const calibrationLineLength = useAppSelector<number | null>(selectCalibrationLineLength)
    const calibrationLineCoordinates = useAppSelector<number[][] | null>(selectCalibrationLineCoords)
    const activeDocumentChunk = useAppSelector(selectActiveDocumentChunk)
    const currentScaleFactor = useAppSelector(selectScaleFactor)
    const isCalibrationVertical = calibrationLineCoordinates
        ? isVertical(calibrationLineCoordinates[0][0], calibrationLineCoordinates[1][0])
        : false

    const updateCalibrationRatio = () => {
        if (!calibrationLineLength || (expectedFeet === null && expectedInches === null)) {
            setErrorModalVisible(true)
            return
        }

        const totalExpectedInches = (expectedFeet ?? 0) * 12 + (expectedInches ?? 0)

        const dpi = activeDocumentChunk && activeDocumentChunk.dpi ? activeDocumentChunk.dpi : DPI_72

        const paperLineLengthInInches = calibrationLineLength / dpi
        const lineLengthAfterScaleInFeet =
            (paperLineLengthInInches * currentScaleFactor) / (activeDocumentChunk?.pdf_scale ?? 1)

        // Note we get the input in inches for higher fidelity, we need to convert these to units of feet in order to calibrate correctly using scaleFactor which is were we get /12
        const ratio = totalExpectedInches / 12 / lineLengthAfterScaleInFeet

        dispatch(
            saveUpdatedCalibrationFactorAction({ ratio, totalExpectedInches, lengthInPixels: calibrationLineLength })
        )
    }

    const resetCalibration = () => {
        manager && manager.useTool(Select.NAME)
        setExpectedFeet(null)
        setExpectedInches(null)
    }

    if (!currentScaleFactor) {
        return <MeasurementContainer warningText="Cannot calibrate, please set scale" />
    }

    return (
        <>
            <div className="calibration-set-container">
                <div className="calibration-title">{isCalibrationVertical ? 'Vertical' : 'Horizontal'} Scale:</div>
                <div className="calibration-title">Expected Length:</div>
                <div className="calibration-input">
                    <div className="value-input-container">
                        <input
                            onChange={(event) => setExpectedFeet(Number(event.target.value))}
                            type="number"
                            className="value-input"
                        />
                        '
                    </div>
                    <div className="value-input-container">
                        <input
                            onChange={(event) => setExpectedInches(Number(event.target.value))}
                            type="number"
                            className="value-input"
                        />
                        "
                    </div>
                </div>
                <div className="calibration-buttons">
                    <Button onClick={() => updateCalibrationRatio()} variant="primary">
                        Save
                    </Button>
                    <Button onClick={() => resetCalibration()} variant="secondary">
                        Cancel
                    </Button>
                </div>
            </div>

            {errorModalVisible && (
                <ModalDialogComponent
                    title="Calibration Error"
                    body={CLASSIFICATION_MESSAGES.CALIBRATION}
                    footerClassName="pull-left"
                    footer={
                        <Button
                            onClick={(event) => {
                                event.stopPropagation()
                                setErrorModalVisible(false)
                            }}
                            variant="primary"
                        >
                            OK
                        </Button>
                    }
                    closeButton={false}
                />
            )}
        </>
    )
}

export default CalibrationSetModal
