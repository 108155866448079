import { createAction } from '@reduxjs/toolkit'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { createModification } from '../../../api/projects-api'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { resetMaterialModificationConflicts, selectMaterialModificationConflicts } from '../../slices/masterSetPlan'
import { selectProjectId } from '../../slices/project'
import { draw2DMarkup } from './markupDocument'

export const createModificationAction = createAction('createModification')

export function* handleModificationCreation() {
    const manager: PaperManager | null = yield call(managers.get2DManager)
    const projectId: number | null = yield select(selectProjectId)

    if (!manager || !projectId) return

    const [selectTool]: [Select] = yield call(manager.getTools, [Select.NAME])

    yield call(selectTool.exitSelectMode)

    const { materialModificationConflictsToSave }: ReturnType<typeof selectMaterialModificationConflicts> =
        yield select(selectMaterialModificationConflicts)

    if (materialModificationConflictsToSave?.length) {
        yield call(createModification, materialModificationConflictsToSave, projectId)
    }

    yield put(resetMaterialModificationConflicts())

    // return original state of items
    yield put(draw2DMarkup())
}

export function* watchForModificationCreation() {
    yield takeLatest(createModificationAction.type, handleModificationCreation)
}
