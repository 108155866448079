import { FormValidation, UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'
import { deckOrPorch } from '../utils/formSchema/constants'

const roomsFlag = process.env.REACT_APP_ROOMS_FLAG === 'true'

export const uiSchema: UiSchema = {
    'ui:order': [
        'sub_floor',
        'sub_floor_adhesive',
        'is_wet_area',
        'location',
        'baseboard',
        'shoe_mould',
        'chair_rail',
        'crown_mould',
    ],
    location: {
        'ui:widget': 'hidden',
    },
    sub_floor: {
        'ui:title': 'Subfloor',
        'ui:placeholder': 'Select Subfloor',
    },
    sub_floor_adhesive: {
        'ui:title': 'Subfloor Adhesive',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    is_wet_area: {
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    bundle_name: {
        'ui:placeholder': 'Select Sub-Region',
    },
    bundle_location: {
        'ui:placeholder': 'Select Sub-Region Location',
    },
    bundle_floor_level: {
        'ui:placeholder': 'Select Sub-Region Floor Level',
    },
    bundle_other_floor_level: {
        'ui:placeholder': 'Enter Sub-Region Other Floor Level',
    },
    baseboard: {
        'ui:widget': roomsFlag ? 'radio' : 'hidden',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    shoe_mould: {
        'ui:widget': roomsFlag ? 'radio' : 'hidden',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    chair_rail: {
        'ui:widget': roomsFlag ? 'radio' : 'hidden',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
    crown_mould: {
        'ui:widget': roomsFlag ? 'radio' : 'hidden',
        'ui:field': 'RadioButtons',
        'ui:options': {
            inline: true,
        },
    },
}

const onValidate = (formData: any, errors: FormValidation) => {}

const onSubmit = (settings, formData) => {
    const newSettings = { ...settings, ...formData }

    if (!deckOrPorch.includes(formData.bundle_name)) {
        newSettings.bundle_floor_level = null
    }

    if (formData.bundle_floor_level !== 'other') {
        newSettings.bundle_other_floor_level = null
    }

    return newSettings
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
