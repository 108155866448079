import { createSelector } from '@reduxjs/toolkit'
import { AllEffect, StrictEffect, all, call, put, select } from 'redux-saga/effects'
import { DELETE_OPENING_GROUPS } from '../../../actions/drawable'
import { deleteOpeningGroupMaterial } from '../../../api/projects-api'
import { ActiveDrawable } from '../../../models/activeDrawable'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIAutomatedObject, AISuggestion } from '../../../models/aiClassifications'
import { Project } from '../../../models/project'
import { getActiveFloor } from '../../../reducers/drawable'
import { RootState } from '../../../stores'
import { parseAiDataIntoDrawableSuggestions } from '../../../utils/aiSuggestions/aiParser'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Count, PathTool, PolygonTool, Workspace } from '../../lib/toolBoxes/2D'
import { selectAiSuggestionsForPage, setDrawableLocations } from '../../slices/2D'
import { GeometryGroup } from '../../slices/geometry'
import { IMUP2DDrawableLocation } from '../../types'
import { selectProject } from '../2D/createDrawableLocation'
import drawAiSuggestionsByType from '../2D/drawAiSuggestionsByType'
import { selectDrawableGroups } from '../2D/handleToggleDrawableGroups'
import { setDrawables } from './createDrawableGroup'
export const selectDrawableLocations = createSelector(
    (state: RootState) => {
        return state.IMUP['2D'].drawableLocations
    },
    (state) => state
)

type Tools = [Color, PathTool, Count, PolygonTool]

export function* deleteTemporaryDrawableGroups(): Generator<
    | StrictEffect
    | AllEffect<GeometryGroup>
    | AllEffect<number>
    | GeometryGroup[]
    | number[]
    | Tools
    | IMUP2DDrawableLocation[],
    void,
    GeometryGroup[] &
        PaperManager &
        Workspace &
        Project &
        number[] &
        IMUP2DDrawableLocation[] &
        Tools &
        AIAutomatedObject &
        AISuggestion[]
> {
    try {
        const manager: PaperManager | null = yield call(managers.get2DManager)

        if (!manager) return

        const project: Project = yield select(selectProject)
        // Cleanup drawables that have no openings
        const allDrawableGroups: GeometryGroup[] = yield select(selectDrawableGroups)

        const allDrawableLocations: IMUP2DDrawableLocation[] = yield select(selectDrawableLocations)

        const drawableGroupsToDelete: GeometryGroup[] = yield allDrawableGroups.filter(
            (drawable) =>
                !drawable.openings ||
                (drawable.openings && drawable.openings.length === 0) ||
                drawable.settings.name === '' ||
                drawable?.settings?.name?.includes('temporary')
        )

        if (!drawableGroupsToDelete.length) return

        const allGroupIdsToDelete: number[] = yield drawableGroupsToDelete.map((group) => group.id)

        const filteredDrawableLocations = yield allDrawableLocations.filter(
            (loc) => !allGroupIdsToDelete.includes(loc.opening_group_id)
        )

        const workspaceTool: Workspace = yield call(manager.getTool, Workspace.NAME)

        const drawablesWithOpenings: GeometryGroup[] = yield allDrawableGroups.filter(
            (drawable) =>
                drawable.openings &&
                drawable.openings.length !== 0 &&
                drawable.settings.name !== '' &&
                !drawable.settings.name?.includes('temporary')
        )

        try {
            // api call to remove it from db
            yield all(
                drawableGroupsToDelete.map((drawableGroup) =>
                    call(deleteOpeningGroupMaterial, project.id, drawableGroup.id)
                )
            )
        } catch (e) {
            yield call(console.error, e)
            return
        }

        // delete drawables from blueprint
        yield all(
            drawableGroupsToDelete.map((drawableGroup) =>
                call(workspaceTool.removeItemsWithOpeningGroupId, drawableGroup.id)
            )
        )

        //update Drawable locations to delete locations
        yield put(setDrawableLocations(filteredDrawableLocations))

        yield put({
            type: DELETE_OPENING_GROUPS,
            payload: { openingGroupsToDelete: drawableGroupsToDelete },
        })

        yield put(setDrawables(drawablesWithOpenings as unknown as ActiveDrawable[]))

        // If a temporary group was based on an AI suggestion, draw AI a suggestion back
        const drawablesBasedOnAi = drawableGroupsToDelete
            .map((group) => group.openings.filter((o) => o.ai_suggestion_id))
            .flat()

        if (drawablesBasedOnAi.length) {
            // get tools from manager
            const [workspaceTool]: [Workspace] = yield call(manager.getTools, [Workspace.NAME])

            const activeFloor: ActiveFloor = yield select(getActiveFloor)
            const aiSuggestionsPerPage: AIAutomatedObject[] = yield select(selectAiSuggestionsForPage, activeFloor.id)
            const aiToRedraw: AIAutomatedObject[] = aiSuggestionsPerPage.filter((ai) =>
                drawablesBasedOnAi.find((dr) => dr.ai_suggestion_id === ai.id && !ai.isDeleted)
            )
            const aiDrawables: AISuggestion[] = parseAiDataIntoDrawableSuggestions(aiToRedraw)

            const regionPaths: paper.Path[] = workspaceTool.getAllRegionItems() as paper.Path[]

            yield all(aiDrawables?.map((drawable) => call(drawAiSuggestionsByType, drawable, 1, 1, regionPaths)))
        }
    } catch (e) {
        yield call(console.error, e)
    }
}
