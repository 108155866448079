import isNull from 'lodash/isNull'
import { call, CallEffect, select, SelectEffect } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { getActiveFloor } from '../../../reducers/drawable'
import { MATERIAL_MODIFICATION_CONFLICT_COLOR } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color } from '../../lib/toolBoxes/2D'
import WorkspaceTool from '../../lib/toolBoxes/2D/tools/workspace/Workspace.tool'
import addSelectFunctionalityToModifications from '../../lib/utils/functionality-bindings/addSelectFunctionalityToModifications'
import { selectMapDrawableLocationsByOpeningLocationId } from '../../slices/2D'
import { IMUP2DDrawableLocation, ITool } from '../../types'
import createPathItemFromType, { getScaleFactor } from './createPathItemFromType'
import addMetadataToPath from './data-prep/addMetadataToPath'

type DrawConflictByTypeYield =
    | CallEffect<paper.Color | paper.Path | null | PaperManager | ITool[] | void | Map<number, IMUP2DDrawableLocation>>
    | SelectEffect
    | (IMUP2DDrawableLocation | undefined)

type DrawConflictByTypeNext = paper.Color &
    paper.Path &
    managerTools &
    PaperManager &
    ActiveFloor &
    Map<number, IMUP2DDrawableLocation> &
    (IMUP2DDrawableLocation | undefined)

type managerTools = [Color, WorkspaceTool]

export default function* drawConflictByType(
    materialModificationConflict: IMaterialModificationConflict,
    areaOpacity: number,
    lineOpacity: number,
    regionPaths: paper.Path[]
): Generator<DrawConflictByTypeYield, paper.Path | null, DrawConflictByTypeNext> {
    // get the 2D drawing manager
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager)) return null

    const mapDrawableLocations: ReturnType<typeof selectMapDrawableLocationsByOpeningLocationId> = yield select(
        selectMapDrawableLocationsByOpeningLocationId
    )

    const assignedLocation: IMUP2DDrawableLocation | undefined = yield mapDrawableLocations.get(
        materialModificationConflict.material_location_id
    )

    if (!assignedLocation) return null

    const [colorTool, workspaceTool]: managerTools = yield call(manager.getTools, [Color.NAME, WorkspaceTool.NAME])

    const color = materialModificationConflict.shapeColor ?? MATERIAL_MODIFICATION_CONFLICT_COLOR.UNTOUCHED
    const shapeColor: ReturnType<typeof colorTool.createColor> = yield call(colorTool.createColor, color)

    const path: paper.Path | null = yield call(
        createPathItemFromType,
        assignedLocation,
        materialModificationConflict.coordinates,
        areaOpacity,
        lineOpacity,
        shapeColor,
        regionPaths
    )

    const activeFloor: ActiveFloor = yield select(getActiveFloor)
    const scaleFactor = getScaleFactor(
        materialModificationConflict.coordinates,
        workspaceTool,
        activeFloor,
        regionPaths
    )

    // add metadata to paths
    yield call(addMetadataToPath, path, scaleFactor, materialModificationConflict)
    yield call(addSelectFunctionalityToModifications, path)

    return path
}
