import { all, call, put, SagaReturnType, select, StrictEffect, throttle } from 'redux-saga/effects'
import { updateProjectContextMarkups } from '../../../api/projects-api'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import { IToolObject } from '../../../models/tool'
import { defineLocationRegion } from '../../../utils/coordinates/defineLocationRegion'
import managers from '../../lib/managers'
import { Color, Label, PathTool, Workspace, Select } from '../../lib/toolBoxes/2D'
import { resetUpdatedCoordinates } from '../../slices/2D'
import { selectAllRegions } from '../../slices/region'
import {
    selectActiveToolObject,
    selectLineOpacity,
    setActiveToolObjectId,
    updateToolObjectByToolId,
    updateToolObjectCoordinates,
} from '../../slices/tools'
import { SAGA_THROTTLE_TIMER, TOOL_TYPE_ENUMS } from '../../types'
import { selectProject } from './createDrawableLocation'
import drawToolByType from './drawToolByType'
import { getMeasurementLabel } from './handleCreateMeasurements'
import isUndefined from 'lodash/isUndefined'

export function* handleToolCoordinatesUpdate(action: ReturnType<typeof updateToolObjectCoordinates>) {
    try {
        if (!action.payload?.coordinates?.length) return

        const paperManager = yield call(managers.get2DManager)

        if (!paperManager) return

        const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

        const currentToolObject: IToolObject = yield select(selectActiveToolObject)

        const items: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) => data?.toolObject?.id === currentToolObject.id
        )

        if (!items.length) return

        const project: Project = yield select(selectProject)

        const regions: Region[] = yield select(selectAllRegions)

        // could be group or path, get the path
        const itemPath = items.find((item) => isUndefined(item?.children)) as paper.Path

        const regionId = defineLocationRegion(itemPath, regions, workspaceTool)

        const measurementLabel = yield getMeasurementLabel(itemPath)

        const toolObjectWithUpdatedData = {
            ...currentToolObject,
            coordinates: action.payload.coordinates,
            region_id: regionId,
            settings: {
                ...currentToolObject.settings,
                label: measurementLabel,
            },
        }

        const updatedContextMarkup: SagaReturnType<typeof updateProjectContextMarkups> = yield call(
            updateProjectContextMarkups,
            project.id,
            currentToolObject.document_chunk_id,
            toolObjectWithUpdatedData
        )

        if (currentToolObject.type === TOOL_TYPE_ENUMS.MEASUREMENT) {
            const [selectTool, colorTool, pathTool, workspaceTool, labelTool] = yield call(paperManager.getTools, [
                Select.NAME,
                Color.NAME,
                PathTool.NAME,
                Workspace.NAME,
                Label.NAME,
            ])

            const lineOpacity: number = yield select(selectLineOpacity)

            yield call(selectTool.removeHandles)

            yield all(items.map((item) => call(item.remove.bind(item))))

            yield call(drawToolByType, updatedContextMarkup, colorTool, pathTool, workspaceTool, lineOpacity, labelTool)
        }

        yield put(resetUpdatedCoordinates())

        // update tool object in all tool objects
        yield put(updateToolObjectByToolId(updatedContextMarkup))

        // we set active tool id to update active tool object
        yield put(setActiveToolObjectId(updatedContextMarkup.id))
    } catch (error) {
        yield call(console.error, (error as Error).message)
    }
}

export function* watchForToolCoordinatesUpdate(): Generator<StrictEffect, void, unknown> {
    yield throttle(SAGA_THROTTLE_TIMER, updateToolObjectCoordinates.type, handleToolCoordinatesUpdate)
}
