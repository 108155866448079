import { UiSchema } from '@rjsf/core'
import { FormProperties } from '../imup/types'

const selectionLP = 'LP'
const selectionFiberCement = 'FIBER CEMENT'
const selectionCemtrim = 'CEMTRIM'
export const selectionWoodtone = 'WOODTONE'
const crownLF = 'L/F CROWN'
const horizontalRailLF = 'L/F HORIZONTAL RAIL'
const stoneLedgeLF = 'L/F STONE LEDGE'
const verticalRailLF = 'L/F VERTICAL RAIL'
const selectionCap = 'CAP'
const selectionSteel = 'STEEL'
const selectionStucco = 'STUCCO'
const bottomWindow = 'BOTTOM WINDOW'

export const woodtoneThickness2X = '2X'
export const woodtoneThickness4X = '4/4X'
export const woodtoneThickness5X = '5/4X'

export const uiSchema: UiSchema = {
    label: {
        'ui:title': 'Label',
    },
    location: {
        'ui:placeholder': 'Select Trim Location',
    },
    thickness: {
        'ui:placeholder': 'None',
    },
    width: {
        'ui:placeholder': 'Select Width',
    },
    width_four_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_two_feet: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    width_five_feet_four_inches: {
        'ui:title': 'Woodtone Width',
        'ui:placeholder': 'Select Woodtone Width',
    },
    texture: {
        'ui:placeholder': 'Not Specified',
    },
    selection: {
        'ui:placeholder': 'Select Selection',
    },
    include_sill: {
        'ui:title': 'Interior Window Sill',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    include_apron: {
        'ui:title': 'Interior Window Apron',
        'ui:field': 'SwitchField',
        'ui:options': {
            inline: true,
        },
    },
    'ui:order': [
        'location',
        'include_sill',
        'include_apron',
        'selection',
        'label',
        'thickness',
        'width',
        'width_four_feet_four_inches',
        'width_two_feet',
        'width_five_feet_four_inches',
        'texture',
    ],
}

const onValidate = () => {}

const onSubmit = (settings, formData) => {
    const preparedFormData = {
        ...settings,
        ...formData,
        texture:
            formData.selection === selectionFiberCement ||
            formData.selection === selectionLP ||
            formData.selection === selectionWoodtone
                ? formData.texture
                : null,
        thickness: formData.selection !== selectionCemtrim ? formData.thickness : null,
    }

    if ([crownLF, horizontalRailLF, stoneLedgeLF, verticalRailLF].includes(preparedFormData.selection)) {
        preparedFormData.location = null
        preparedFormData.thickness = null
        preparedFormData.width = null
        preparedFormData.include_sill = null
        preparedFormData.include_apron = null
    }

    if (preparedFormData.location !== bottomWindow) {
        preparedFormData.include_sill = null
        preparedFormData.include_apron = null
    }

    if (formData.selection === selectionWoodtone) {
        if (preparedFormData?.width_five_feet_four_inches) {
            preparedFormData.width = preparedFormData?.width_five_feet_four_inches
            delete preparedFormData?.width_five_feet_four_inches
        }

        if (preparedFormData?.width_two_feet) {
            preparedFormData.width = preparedFormData?.width_two_feet
            delete preparedFormData?.width_two_feet
        }

        if (preparedFormData?.width_four_feet_four_inches) {
            preparedFormData.width = preparedFormData?.width_four_feet_four_inches
            delete preparedFormData?.width_four_feet_four_inches
        }
    }

    if ([selectionCap, selectionSteel, selectionStucco].includes(formData.selection)) {
        preparedFormData.thickness = null
        preparedFormData.width = null
    }

    if (formData.selection !== selectionWoodtone) {
        preparedFormData.texture = null
        delete preparedFormData.width_five_feet_four_inches
        delete preparedFormData.width_four_feet_four_inches
        delete preparedFormData.width_two_feet
    }

    return preparedFormData
}

const form: FormProperties = { uiSchema, onValidate, onSubmit }

export default form
