import { createAction } from '@reduxjs/toolkit'
import { ForkEffect, call, put, takeLatest } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { setMeasurementsVisibility } from '../../slices/tools'
import { TOOL_TYPE_ENUMS } from '../../types'

export const toggleMeasurementLinesVisibilityAction = createAction<boolean>('toggleMeasurementLinesVisibility')

export function* handleToggleMeasurementLinesVisibility({
    payload: isMeasurementLinesVisible,
}: ReturnType<typeof toggleMeasurementLinesVisibilityAction>) {
    const paperManager: PaperManager | null = yield call(managers.get2DManager)

    if (!paperManager) return

    const workspaceTool: Workspace = yield call(paperManager.getTool, Workspace.NAME)

    yield put(setMeasurementsVisibility(isMeasurementLinesVisible))

    const measurementItems: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data?.drawing_type === TOOL_TYPE_ENUMS.MEASUREMENT
    )

    // check if the state is different from previous
    if (measurementItems[0]?.visible !== isMeasurementLinesVisible) {
        measurementItems.forEach((r) => {
            r.visible = isMeasurementLinesVisible

            const labelItem = workspaceTool.getItemWithPaperId<paper.TextItem>(r.data.labelId)
            if (labelItem) {
                labelItem.visible = isMeasurementLinesVisible
            }
        })
    }
}

export function* watchForToggleMeasureLinesVisibility(): Generator<ForkEffect<never>, void, unknown> {
    yield takeLatest(toggleMeasurementLinesVisibilityAction.type, handleToggleMeasurementLinesVisibility)
}
