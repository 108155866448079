import { createAction } from '@reduxjs/toolkit'
import { call, put, select, takeEvery } from 'redux-saga/effects'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Select } from '../../lib/toolBoxes/2D'
import { copyDrawables, multiSelectDrawables, selectCopiedIds } from '../../slices/2D'

export const removeGroupIdsFromMultiSelectItemsAction = createAction<number[]>('removeGroupIdsFromMultiSelect')

export function* handleRemoveGroupIdsFromMultiSelection({
    payload,
}: ReturnType<typeof removeGroupIdsFromMultiSelectItemsAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    const selectTool: Select = yield call(manager.getTool, Select.NAME)

    const copiedDrawableIds: number[] = yield select(selectCopiedIds)

    yield call(
        selectTool.removeItemsWithPredicateFromMultiSelect,
        (item: paper.Item) => !payload.includes(item.data.opening_group_id)
    )

    const selectedDrawableIds: number[] = yield call(selectTool.calculateDrawableIdsOfMultiSelectedItems)

    yield put(multiSelectDrawables(selectedDrawableIds))

    if (copiedDrawableIds.length > 0) {
        yield put(copyDrawables(selectedDrawableIds))
    }
}

export function* watchHandleRemoveGroupIdsFromMultiSelection() {
    yield takeEvery(removeGroupIdsFromMultiSelectItemsAction, handleRemoveGroupIdsFromMultiSelection)
}
