import { createAction } from '@reduxjs/toolkit'
import { all, call, ForkEffect, put, select, takeEvery } from 'redux-saga/effects'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { duplicateDocumentChunkAndEstimations } from '../../../api/projects-api'
import { fetchOpeningGroupsByProjectId } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { DocumentChunk } from '../../../models/documentChunk'
import { DocumentMapping } from '../../../models/documentMapping'
import { Project } from '../../../models/project'
import { Region } from '../../../models/region'
import { setDrawableLocations } from '../../slices/2D'
import {
    initializeDocumentChunks,
    initializeProjectDocumentsFromChunks,
    selectDocumentChunks,
    selectDocumentMappings,
    updateDocumentMappings,
} from '../../slices/documents'
import { addRegionToStore } from '../../slices/region'
import { IMUP2DDrawableLocation } from '../../types'
import { selectProject } from './createDrawableLocation'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'

export const duplicateDocumentChunkAndEstimationsAction = createAction<{ documentChunkId: number }>(
    'duplicateDocumentChunkAndEstimations'
)

export function* handleDuplicateDocumentChunkAndEstimations({
    payload,
}: ReturnType<typeof duplicateDocumentChunkAndEstimationsAction>) {
    const documentChunks: DocumentChunk[] = yield select(selectDocumentChunks)
    const documentMappings: DocumentMapping[] = yield select(selectDocumentMappings)
    const project: Project = yield select(selectProject)

    try {
        const { document_chunk_mappings: documentMapping, ...documentChunk } = yield call(
            duplicateDocumentChunkAndEstimations,
            project.id,
            payload.documentChunkId
        )

        yield put(updateDocumentMappings([...documentMappings, documentMapping[0]]))

        yield put(initializeDocumentChunks([...documentChunks, documentChunk]))
        yield put(initializeProjectDocumentsFromChunks([...documentChunks, documentChunk]))
        yield all(documentChunk.regions.map((region: Region) => put(addRegionToStore(region))))
        const openingGroups: OpeningGroupAPI[] = yield call(fetchOpeningGroupsByProjectId, project.id)

        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        const drawableLocations: IMUP2DDrawableLocation[] = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        yield put(setDrawableLocations(drawableLocations))
    } catch (e) {
        yield call(console.error, `Error on API duplication: ${e}`)

        yield put(updateDocumentMappings(documentMappings))

        yield put(initializeDocumentChunks(documentChunks))
        yield put(initializeProjectDocumentsFromChunks(documentChunks))
    }
}

export function* watchForDuplicateDocumentChunkAndEstimations(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(duplicateDocumentChunkAndEstimationsAction.type, handleDuplicateDocumentChunkAndEstimations)
}
