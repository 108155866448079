import isEmpty from 'lodash/isEmpty'
import { getAuthorizationHeader } from '../../../authConfig'
import { GeometryInputMap } from '../../sagas/3D/data-prep/prepare3DModel'
import { IModel, OpeningsResponse, ThreeDElementsResponse } from '../../types'

const projectApi = process.env.REACT_APP_PORTAL_FILE_STORAGE_API

export const getTwoDMappingJSON = async (projectNumber: string): Promise<any> => {
    const fetchURL = `${projectApi}/${projectNumber}/${projectNumber}-3DGenerated.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
}

export const getThreeDInputJSON = async (projectNumber: string): Promise<any> => {
    const fetchURL = `${projectApi}/${projectNumber}/${projectNumber}-3DInput.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then((res) => res.json())
        .then((res) => {
            if (res.error) {
                throw res.error
            }
            return res
        })
}

export const getStructureObjectJSON = async (projectNumber: string): Promise<IModel> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/structurenodedto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            return res.objects[0]
        })
}

export const getGeometriesJSON = async (projectNumber: string): Promise<GeometryInputMap> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/geometrydto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedGeometries = {}
            res.objects.forEach((object) => {
                preparedGeometries[object.checksum] = object
            })

            return preparedGeometries
        })
}

export const getRoofFacesJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/rooffacedto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedRoofFaces = {}
            res.objects.forEach((object) => {
                preparedRoofFaces[object.id] = {
                    id: object.id,
                    roofFaceData: {
                        ...object,
                        contour: object.contour.map(({ number, ...rest }) => rest),
                    },
                }

                delete preparedRoofFaces[object.id].roofFaceData.normal

                preparedRoofFaces[object.id].data = JSON.stringify(preparedRoofFaces[object.id].roofFaceData)
            })

            return preparedRoofFaces
        })
}

export const getOverFramedRoofFacesJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/overframedrooffacedto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedOverFramedRoofFaces = {}
            res.objects.forEach((object) => {
                preparedOverFramedRoofFaces[object.id] = {
                    id: object.id,
                    roofFaceData: {
                        ...object,
                        contour: object.contour.map(({ number, ...rest }) => rest),
                    },
                }

                delete preparedOverFramedRoofFaces[object.id].roofFaceData.normal

                preparedOverFramedRoofFaces[object.id].data = JSON.stringify(
                    preparedOverFramedRoofFaces[object.id].roofFaceData
                )
            })

            return preparedOverFramedRoofFaces
        })
}

export const getExteriorCornersJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/exteriorcornerdto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedExteriorCorner = {}
            res.objects.forEach((object) => {
                preparedExteriorCorner[object.id] = {
                    id: object.id,
                    exteriorCornerData: {
                        ...object,
                    },
                }

                preparedExteriorCorner[object.id].data = JSON.stringify(
                    preparedExteriorCorner[object.id].exteriorCornerData
                )
            })

            return preparedExteriorCorner
        })
}

export const getRoofSingleEdgesJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/roofsingleedgedto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedRoofSingleEdges = {}
            res.objects.forEach((object) => {
                preparedRoofSingleEdges[object.id] = {
                    id: object.id,
                    roofSingleEdgeData: {
                        ...object,
                        edgesForMup: [
                            {
                                point1: object.point1,
                                point2: object.point2,
                                length: object?.length || null,
                                vector: object?.vector || null,
                            },
                        ],
                    },
                }

                preparedRoofSingleEdges[object.id].data = JSON.stringify(
                    preparedRoofSingleEdges[object.id].roofSingleEdgeData
                )
            })

            return preparedRoofSingleEdges
        })
}

export const getRoofJunctionsJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/roofjunctiondto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedRoofJunctions = {}
            res.objects.forEach((object) => {
                preparedRoofJunctions[object.id] = {
                    id: object.id,
                    roofSingleEdgeData: {
                        ...object,
                    },
                }

                preparedRoofJunctions[object.id].data = JSON.stringify(
                    preparedRoofJunctions[object.id].roofSingleEdgeData
                )
            })

            return preparedRoofJunctions
        })
}

export const getFloorJunctionsJSON = async (projectNumber: string): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/floorjunctiondto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedFloorJunctions = {}
            res.objects.forEach((object) => {
                preparedFloorJunctions[object.id] = {
                    id: object.id,
                    floorJunctionData: {
                        ...object,
                    },
                }

                preparedFloorJunctions[object.id].data = JSON.stringify(
                    preparedFloorJunctions[object.id].floorJunctionData
                )
            })

            return preparedFloorJunctions
        })
}

export const getOpeningSideJSON = async (projectNumber: string): Promise<OpeningsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/openingsidedto.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent) ? {} : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            if (!Object.keys(res).length) return {}

            const preparedOpenings = {}

            res.objects.forEach((object) => {
                preparedOpenings[object.id] = {
                    ...object,
                    openingData: {
                        ...object.edgesMap,
                    },
                }

                preparedOpenings[object.id].data = JSON.stringify(preparedOpenings[object.id])
            })

            return preparedOpenings
        })
}

export const getConfiguratorQuestionsAndAnswersJSON = async (
    projectNumber: string
): Promise<ThreeDElementsResponse> => {
    const fetchURL = `${projectApi}/${projectNumber}/dto/configuratorQuestionsAndAnswers.json`
    const authorization: string = await getAuthorizationHeader()

    return fetch(fetchURL, {
        method: 'GET',
        headers: {
            Authorization: authorization,
        },
    })
        .catch((e) => {
            throw e
        })
        .then(async (res) => {
            const resContent = await res.text()
            return isEmpty(resContent)
                ? { answers: {}, phaseAndDesignOptions: {}, questions: {} }
                : JSON.parse(resContent)
        })
        .then((res) => {
            if (res.error) {
                throw res.error
            }

            // TODO: prepare the data when it would return something,
            //  currently data do not exist, could not prepare the data

            return res
        })
}
