import { createAction, createSelector } from '@reduxjs/toolkit'
import { ForkEffect, all, call, select, takeEvery } from 'redux-saga/effects'
import { ActiveFloor } from '../../../models/activeFloor'
import { AIAutomatedObject, AISuggestion } from '../../../models/aiClassifications'
import { getDrawableActiveFloor } from '../../../reducers/drawable'
import { RootState } from '../../../stores'
import { parseAiDataIntoDrawableSuggestions } from '../../../utils/aiSuggestions/aiParser'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Workspace } from '../../lib/toolBoxes/2D'
import { getCurrentDrawableLocations, selectAiSuggestionsForPage } from '../../slices/2D'
import { ToolsState } from '../../slices/tools'
import { IMUP2DDrawableLocation } from '../../types'
import drawAiSuggestionsByType from './drawAiSuggestionsByType'
import { selectMarkupState } from './markupDocument'

export const drawAISuggestions = createAction('drawAISuggestions')

interface DAISuggestionStoreState {
    activeFloor: ActiveFloor
    drawableLocations: IMUP2DDrawableLocation[]
    areaOpacity: ToolsState['areaOpacityValue']
    lineOpacity: ToolsState['lineOpacityValue']
}

export const selectAISuggestionState = createSelector(
    (state: RootState): DAISuggestionStoreState => ({
        activeFloor: getDrawableActiveFloor(state.drawable),
        drawableLocations: getCurrentDrawableLocations(state.IMUP),
        areaOpacity: state.IMUP.tools.areaOpacityValue,
        lineOpacity: state.IMUP.tools.lineOpacityValue,
    }),
    (state: DAISuggestionStoreState): DAISuggestionStoreState => state
)

export function* handleDrawAISuggestions() {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (!manager) return

    // get markup state from redux store
    const { areaOpacity, lineOpacity, drawableLocations, activeFloor }: ReturnType<typeof selectMarkupState> =
        yield select(selectAISuggestionState)

    // get tools from manager
    const [workspaceTool]: [Workspace] = yield call(manager.getTools, [Workspace.NAME])

    //remove all already Drawn Ai Suggestions before
    const alreadyDrawnAiSuggestions: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data?.aiSuggestion?.id
    )

    alreadyDrawnAiSuggestions.forEach((item) => item.remove())

    // handle AI drawables suggestions
    const aiSuggestions: AIAutomatedObject[] = yield select(selectAiSuggestionsForPage, activeFloor.id)
    if (aiSuggestions?.length) {
        // Filter deleted & used suggestions
        const aiSuggestionsIdSet: Set<string> = new Set(drawableLocations.map((l) => l.ai_suggestion_id))
        const relevantSuggestions: AIAutomatedObject[] = aiSuggestions.filter(
            (aiDrawable) => !aiDrawable.isDeleted && !aiSuggestionsIdSet.has(aiDrawable.id)
        )
        const aiDrawables: AISuggestion[] = parseAiDataIntoDrawableSuggestions(relevantSuggestions)

        const regionPaths = workspaceTool.getAllRegionItems() as paper.Path[]

        yield all(
            aiDrawables.map((drawable) =>
                call(drawAiSuggestionsByType, drawable, areaOpacity, lineOpacity, regionPaths)
            )
        )
    }
}

export function* watchForDrawAISuggestions(): Generator<ForkEffect<never>, void, unknown> {
    yield takeEvery(drawAISuggestions.type, handleDrawAISuggestions)
}
