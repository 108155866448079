import { applyScaleFactorToPathLength } from '../../../../../../utils/calculations/scaleConversion/scaleConversion'
import { PaperToolConfig } from '../../../../../types'
import { PathTool } from '../path/Path.tool'
import { convertAbsoluteDistanceToFormattedString } from '../../../../../../components/markup/utils/helpers'
import throttle from 'lodash/throttle'
import { Coordinate } from '../../../../../../models/activeDrawable'

/**
 * Measure.tool.tsx
 * Calculates distances between two points
 */
export class Measure extends PathTool {
    static NAME = 'MEASURE'

    protected temporaryPath: paper.Path | null = null
    private linePoints: Coordinate[]

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Measure.NAME
        this.linePoints = []
    }

    cancel = () => {
        this.temporaryPath?.remove()

        this.mediator.mediate('tools', { measurementIdToCreate: null })
        this.mediator.mediate('common', {
            tooltip: { title: '', visible: false, color: '#000000' },
        })

        this.temporaryPath = null
        this.linePoints = []
    }

    updateMouseToolTipThrottled = throttle(
        (tooltip: { title: string; color: string; visible: boolean }) =>
            this.mediator.mediate('common', {
                tooltip,
            }),
        15
    )

    onMouseDrag = (event: paper.ToolEvent): void => {
        if (this.toolPanning(event)) return
    }

    onMouseMove = (event: paper.ToolEvent) => {
        if (this.linePoints.length === 1) {
            this.temporaryPath?.remove()

            this.temporaryPath = new this.paper.Path([this.linePoints[0], event.point])
            this.temporaryPath.strokeColor = new this.paper.Color(this.measureStrokeColor)
            this.temporaryPath.strokeWidth = this.measureStrokeWidth
            this.temporaryPath.strokeScaling = false

            this.updateMouseToolTipThrottled({
                title: `${convertAbsoluteDistanceToFormattedString(
                    applyScaleFactorToPathLength({
                        pxValue: this.temporaryPath.length,
                        scaleFactor: this.scaleFactor,
                        dpi: this.getActiveDocumentChunk()?.dpi ?? null,
                        xCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_x ?? 1,
                        yCalibrationFactor: this.getActiveDocumentChunk()?.calibration_factor_y ?? 1,
                        coordinates: [this.linePoints[0], event.point] as Coordinate[],
                        pdfScale: this.getActiveDocumentChunk()?.pdf_scale ?? 1,
                    })
                )}`,
                visible: true,
                color: '#000000',
            })
        }
    }

    onMouseDown = (event: paper.ToolEvent): void => {
        if (this.isPanningClick(event)) return

        this.linePoints.push([event.point.x, event.point.y])

        // update the scale factor after click
        if (this.linePoints.length === 1) {
            const scaleFactor = this.setScaleFromPointClick(event.point)

            if (scaleFactor) {
                this.scaleFactor = scaleFactor
            }
        }

        if (this.linePoints.length === 2 && this.temporaryPath) {
            this.mediator.mediate('tools', { measurementIdToCreate: this.temporaryPath.id })

            this.cancel()
        }
    }
}

export default Measure
