import { DEFAULT_BUNDLE_NAMES } from '../../imup/types'
import { ActiveDrawable, DrawableLocation } from '../../models/activeDrawable'
import { AzureFeatureFlag } from '../../models/azureFeatureFlags'
import { AzureFeatureFlagIds } from '../constants/azure-feature-flags'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../constants/drawable-types'
import { PLAN_VIEWS_ENUM } from '../constants/plan-views'
import { isAzureFeatureFlagEnabled } from './azure-feature-flag-services/azure-feature-flag-services'

export interface DrawableTypeInput {
    drawable: ActiveDrawable
    planView: DrawableLocation
    planType?: string
}

/**
 * determineDrawingType
 * @returns the drawing type based on properties of arguments
 * @param drawableInput
 */
export function determineDrawingType(drawableInput: DrawableTypeInput): DRAWING_TYPES | null {
    let drawingType: DRAWING_TYPES | null = null

    switch (drawableInput.drawable.type) {
        case DRAWABLE_TYPES.LE_JOIST:
        case DRAWABLE_TYPES.I_JOIST:
        case DRAWABLE_TYPES.CEILING_JOIST:
        case DRAWABLE_TYPES.FLOOR_JOIST:
        case DRAWABLE_TYPES.SLEEPER_JOIST:
        case DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING:
        case DRAWABLE_TYPES.ROOF_SYSTEM:
        case DRAWABLE_TYPES.FLOOR_SYSTEM:
            // Section represents joist lines
            if (drawableInput.planView.coordinates.length === 2) {
                drawingType = DRAWING_TYPES.SECTION
            } else {
                drawingType = DRAWING_TYPES.AREA
            }
            break
        case DRAWABLE_TYPES.LE_WINDOW:
        case DRAWABLE_TYPES.LE_DOOR:
            drawingType = DRAWING_TYPES.AREA
            if (drawableInput.planView.additional_data?.is_floor === true) {
                drawingType = DRAWING_TYPES.SECTION
            }
            break

        case DRAWABLE_TYPES.VENT:
        case DRAWABLE_TYPES.INTERIOR_DOOR:
        case DRAWABLE_TYPES.LE_UNKNOWN:
        case DRAWABLE_TYPES.STEEL_BEAM_PLATE:
        case DRAWABLE_TYPES.LF_RAILING:
        case DRAWABLE_TYPES.RAFTER:
        case DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM:
        case DRAWABLE_TYPES.FLOOR_TRUSS_BRACING:
        case DRAWABLE_TYPES.HEADER:
        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.AUTOMATED_BEAM_AND_HEADER:
        case DRAWABLE_TYPES.SHEARWALL:
        case DRAWABLE_TYPES.VALLEY_PLATE:
        case DRAWABLE_TYPES.LF_SCREEN_OPENING:
        case DRAWABLE_TYPES.BLOCKING:
            drawingType = DRAWING_TYPES.SECTION
            break
        case DRAWABLE_TYPES.HARDWARE:
        case DRAWABLE_TYPES.LEDGER:
            if (drawableInput.planView.coordinates.length === 1) {
                drawingType = DRAWING_TYPES.POINT
            } else {
                drawingType = DRAWING_TYPES.SECTION
            }
            break
        case DRAWABLE_TYPES.SIDING:
        case DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING:
            if (
                drawableInput.drawable.settings.name &&
                drawableInput.drawable.settings.name.toLowerCase().includes('starter')
            ) {
                drawingType = DRAWING_TYPES.SECTION
            } else {
                drawingType = DRAWING_TYPES.AREA
            }
            break
        case DRAWABLE_TYPES.ROOFING:
            if (
                drawableInput.drawable.settings.name.toLowerCase().startsWith('ev') ||
                drawableInput.drawable.settings.name.toLowerCase().startsWith('pv') ||
                drawableInput.drawable.settings.name.toLowerCase().includes('porch ceiling')
            ) {
                drawingType = DRAWING_TYPES.AREA
            } else {
                drawingType = DRAWING_TYPES.SECTION
            }
            break
        case DRAWABLE_TYPES.WINDOW:
        case DRAWABLE_TYPES.PATIO:
        case DRAWABLE_TYPES.ENTRY_DOOR:
        case DRAWABLE_TYPES.SIDELIGHT:
        case DRAWABLE_TYPES.AI:
            if (drawableInput.planType === PLAN_VIEWS_ENUM.FLOOR) {
                drawingType = DRAWING_TYPES.SECTION
                break
            }
            drawingType = DRAWING_TYPES.AREA
            break
        case DRAWABLE_TYPES.CORNER:
        case DRAWABLE_TYPES.POST_WRAP:
        case DRAWABLE_TYPES.POST:
        case DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT:
        case DRAWABLE_TYPES.FYPON_BRACKET:
        case DRAWABLE_TYPES.CANALES:
        case DRAWABLE_TYPES.EXTERIOR_DOOR:
        case DRAWABLE_TYPES.INTERIOR_DOOR_IMUP:
        case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
        case DRAWABLE_TYPES.WILDCARD_COUNT:
        case DRAWABLE_TYPES.STAIRS:
        case DRAWABLE_TYPES.FOUNDATION_PILON:
        case DRAWABLE_TYPES.CANTILEVER_CLOSURE:
        case DRAWABLE_TYPES.BLOCK_ROUGH:
        case DRAWABLE_TYPES.APPLIANCE:
            drawingType = DRAWING_TYPES.POINT
            break
        case DRAWABLE_TYPES.WILDCARD_AREA:
        case DRAWABLE_TYPES.GABLE_AREA:
        case DRAWABLE_TYPES.SCREEN_AREA:
        case DRAWABLE_TYPES.FLOOR_AREA:
        case DRAWABLE_TYPES.PORCH_CEILING:
        case DRAWABLE_TYPES.LOUVER_HOG_FENCE:
        case DRAWABLE_TYPES.EXTERIOR_ROOFING:
        case DRAWABLE_TYPES.FRAMING_ROOFING:
        case DRAWABLE_TYPES.GARAGE_AREA:
        case DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING:
            drawingType = DRAWING_TYPES.AREA
            break
        case DRAWABLE_TYPES.WILDCARD_LINE:
        case DRAWABLE_TYPES.GABLE_LENGTH:
        case DRAWABLE_TYPES.EAVE_LENGTH:
        case DRAWABLE_TYPES.SIDING_STARTER:
        case DRAWABLE_TYPES.FLASHING:
        case DRAWABLE_TYPES.ROOF_LINE:
            drawingType = DRAWING_TYPES.SECTION
            break
        case DRAWABLE_TYPES.WALL:
            // If wall has more than one location, it's King Stud, Kind Stud has only one coordinate
            if (
                drawableInput.drawable.opening_locations?.length > 1 &&
                drawableInput.planView.coordinates.length === 1
            ) {
                drawingType = DRAWING_TYPES.POINT
            } else if (
                drawableInput.planView.coordinates.length === 3 &&
                drawableInput.drawable.additional_data?.arc_points
            ) {
                drawingType = DRAWING_TYPES.RADIUS
            } else {
                drawingType = DRAWING_TYPES.SECTION
            }
            break
        // Radius Line Cases
        case DRAWABLE_TYPES.TRIM:
            if (drawableInput.planView.coordinates.length === 3 && drawableInput.drawable.additional_data?.arc_points) {
                drawingType = DRAWING_TYPES.RADIUS
            } else {
                drawingType = DRAWING_TYPES.SECTION
            }
            break
        default:
            break
    }

    return drawingType
}

/**
 * Returns an array of all supported drawing types based on drawable types
 * @param drawableType the drawable type to test
 * @param azureFeatureFlags all featured flags
 * @returns the drawing types that are supported
 */
export function determineAllSupportedDrawingTypes(
    drawableType: DRAWABLE_TYPES,
    azureFeatureFlags: AzureFeatureFlag[] | null
): DRAWING_TYPES[] | null {
    switch (drawableType) {
        case DRAWABLE_TYPES.FLOOR_LINE:
        case DRAWABLE_TYPES.LE_UNKNOWN:
        case DRAWABLE_TYPES.STEEL_BEAM_PLATE:
        case DRAWABLE_TYPES.RAFTER:
        case DRAWABLE_TYPES.FLOOR_TRUSS_SYSTEM:
        case DRAWABLE_TYPES.FLOOR_TRUSS_BRACING:
        case DRAWABLE_TYPES.HEADER:
        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.AUTOMATED_BEAM_AND_HEADER:
        case DRAWABLE_TYPES.PATIO:
        case DRAWABLE_TYPES.SIDELIGHT:
        case DRAWABLE_TYPES.WILDCARD_LINE:
        case DRAWABLE_TYPES.GABLE_LENGTH:
        case DRAWABLE_TYPES.EAVE_LENGTH:
        case DRAWABLE_TYPES.SIDING_STARTER:
        case DRAWABLE_TYPES.LF_RAILING:
        case DRAWABLE_TYPES.FLASHING:
        case DRAWABLE_TYPES.ROOF_LINE:
        case DRAWABLE_TYPES.VALLEY_PLATE:
        case DRAWABLE_TYPES.LF_SCREEN_OPENING:
            return [DRAWING_TYPES.SECTION]
        case DRAWABLE_TYPES.HARDWARE:
            return [DRAWING_TYPES.POINT, DRAWING_TYPES.SECTION]
        case DRAWABLE_TYPES.SIDING:
        case DRAWABLE_TYPES.LE_JOIST:
        case DRAWABLE_TYPES.I_JOIST:
        case DRAWABLE_TYPES.CEILING_JOIST:
        case DRAWABLE_TYPES.FLOOR_JOIST:
        case DRAWABLE_TYPES.SLEEPER_JOIST:
        case DRAWABLE_TYPES.LE_WINDOW:
        case DRAWABLE_TYPES.LE_DOOR:
        case DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING:
        case DRAWABLE_TYPES.WILDCARD_AREA:
        case DRAWABLE_TYPES.GABLE_AREA:
        case DRAWABLE_TYPES.FLOOR_AREA:
        case DRAWABLE_TYPES.BASEMENT_FINISHED_CEILING:
        case DRAWABLE_TYPES.SCREEN_AREA:
        case DRAWABLE_TYPES.EXTERIOR_ROOFING:
        case DRAWABLE_TYPES.FRAMING_ROOFING:
        case DRAWABLE_TYPES.PORCH_CEILING:
        case DRAWABLE_TYPES.ROOF_SYSTEM:
        case DRAWABLE_TYPES.FLOOR_SYSTEM:
        case DRAWABLE_TYPES.ENTRY_DOOR:
        case DRAWABLE_TYPES.LOUVER_HOG_FENCE:
        case DRAWABLE_TYPES.GARAGE_AREA:
            return [DRAWING_TYPES.AREA]
        case DRAWABLE_TYPES.CORNER:
        case DRAWABLE_TYPES.POST_WRAP:
        case DRAWABLE_TYPES.POST:
        case DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT:
        case DRAWABLE_TYPES.FYPON_BRACKET:
        case DRAWABLE_TYPES.CANALES:
        case DRAWABLE_TYPES.EXTERIOR_DOOR:
        case DRAWABLE_TYPES.INTERIOR_DOOR_IMUP:
        case DRAWABLE_TYPES.WILDCARD_COUNT:
        case DRAWABLE_TYPES.STAIRS:
        case DRAWABLE_TYPES.FOUNDATION_PILON:
        case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
        case DRAWABLE_TYPES.BLOCK_ROUGH:
        case DRAWABLE_TYPES.CANTILEVER_CLOSURE:
        case DRAWABLE_TYPES.APPLIANCE:
            return [DRAWING_TYPES.POINT]
        case DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING:
            return [DRAWING_TYPES.AREA, DRAWING_TYPES.SECTION]
        case DRAWABLE_TYPES.WALL:
        case DRAWABLE_TYPES.TRIM:
            return [DRAWING_TYPES.RADIUS, DRAWING_TYPES.SECTION]
        case DRAWABLE_TYPES.LEDGER:
            const isLedgerEnhancementsEnabled = isAzureFeatureFlagEnabled(
                azureFeatureFlags,
                AzureFeatureFlagIds.ledger_enhancement
            )
            return isLedgerEnhancementsEnabled ? [DRAWING_TYPES.SECTION] : [DRAWING_TYPES.POINT]
        case DRAWABLE_TYPES.BLOCKING:
            const isBlockingEnhancementsEnabled = isAzureFeatureFlagEnabled(
                azureFeatureFlags,
                AzureFeatureFlagIds.new_blocking_tool
            )
            return isBlockingEnhancementsEnabled ? [DRAWING_TYPES.SECTION] : null
        default:
            return null
    }
}

export const determineDefaultBundleForNewGroup = (newGroupType: DRAWABLE_TYPES): DEFAULT_BUNDLE_NAMES => {
    switch (newGroupType) {
        case DRAWABLE_TYPES.APPLIANCE:
            return DEFAULT_BUNDLE_NAMES.APPLIANCES

        case DRAWABLE_TYPES.SIDING:
        case DRAWABLE_TYPES.EXTERIOR_ROOFING:
        case DRAWABLE_TYPES.SIDING_STARTER:
        case DRAWABLE_TYPES.FYPON_BRACKET:
        case DRAWABLE_TYPES.FLASHING:
        case DRAWABLE_TYPES.POST_WRAP:
        case DRAWABLE_TYPES.PORCH_CEILING:
        case DRAWABLE_TYPES.ROOF_LINE:
            return DEFAULT_BUNDLE_NAMES.EXTERIOR

        case DRAWABLE_TYPES.STEEL_BEAM_PLATE:
            return DEFAULT_BUNDLE_NAMES.POST_AND_BEAMS

        case DRAWABLE_TYPES.FOUNDATION_PILON:
            return DEFAULT_BUNDLE_NAMES.FOUNDATION_MATERIAL

        case DRAWABLE_TYPES.HARDWARE:
            return DEFAULT_BUNDLE_NAMES.HARDWARE

        case DRAWABLE_TYPES.CANALES:
        case DRAWABLE_TYPES.INTERIOR_FINISHED_CEILING:
        case DRAWABLE_TYPES.RIDGE_BEAM:
        case DRAWABLE_TYPES.HIP_AND_VALLEY_BEAM:
        case DRAWABLE_TYPES.VALLEY_PLATE:
        case DRAWABLE_TYPES.UNDER_VALLEY_SHEATHING:
        case DRAWABLE_TYPES.ROOF_SYSTEM:
            return DEFAULT_BUNDLE_NAMES.ROOF_FRAMING

        case DRAWABLE_TYPES.INTERIOR_DOOR_IMUP:
        case DRAWABLE_TYPES.EXTERIOR_DOOR:
            return DEFAULT_BUNDLE_NAMES.MILLWORK

        case DRAWABLE_TYPES.LF_FIVE_DOT_FIVE_DECKING:
        case DRAWABLE_TYPES.LF_RAILING:
            return DEFAULT_BUNDLE_NAMES.DECK

        case DRAWABLE_TYPES.SCREEN_AREA:
        case DRAWABLE_TYPES.LF_SCREEN_OPENING:
            return DEFAULT_BUNDLE_NAMES.SCREEN_PORCH

        case DRAWABLE_TYPES.FLOOR_SYSTEM:
        case DRAWABLE_TYPES.FLOOR_TRUSS_BRACING:
        case DRAWABLE_TYPES.CANTILEVER_CLOSURE:
        case DRAWABLE_TYPES.LEDGER:
        case DRAWABLE_TYPES.BLOCKING:
        case DRAWABLE_TYPES.POST:
        case DRAWABLE_TYPES.TRAYED_CEILING_AND_SOFFIT:
        case DRAWABLE_TYPES.DROPPED_BEAM:
        case DRAWABLE_TYPES.FLUSH_BEAM:
        case DRAWABLE_TYPES.HEADER:
            return DEFAULT_BUNDLE_NAMES.EMPTY

        default:
            return DEFAULT_BUNDLE_NAMES.FRAMING
    }
}

export default determineDrawingType
