import { Coordinate } from '../../../../../../models/activeDrawable'
import { JOIST_COLOR } from '../../../../../../shared/constants/colors'
import { isNonAreaJoistLine } from '../../../../../../utils/project/project-helper-functions'
import { PaperToolConfig, REGION_ENUMS } from '../../../../../types'
import { Arrow } from '../arrow/arrow.tool'
import Base from '../paperTool/PaperTool'

/**
 * Workspace.tool.tsx
 * High level Paper.js workspace management functions that modify the Paper.project state
 */
export class Workspace extends Base {
    static NAME = 'WORKSPACE'

    public defaultLabelBGColor: paper.Color
    public doubleJoistLabelBGColor: paper.Color
    public singleJoistLabelTextColor: paper.Color
    public doubleJoistLabelTextColor: paper.Color

    constructor(config: PaperToolConfig) {
        super(config)
        this.name = Workspace.NAME

        this.defaultLabelBGColor = new this.paper.Color(JOIST_COLOR.WHITE)
        this.doubleJoistLabelBGColor = new this.paper.Color(JOIST_COLOR.BLUE)
        this.singleJoistLabelTextColor = new this.paper.Color(JOIST_COLOR.BLACK)
        this.doubleJoistLabelTextColor = new this.paper.Color(JOIST_COLOR.WHITE)
    }

    clear = (): void => {
        const viewElement = this.paper.view.element
        this.paper.project.remove()
        this.paper.project = new this.paper.Project(viewElement)
    }

    undo = (): this => {
        this.paper.project.activeLayer.lastChild.remove()

        return this
    }

    getItemWithPaperId = <T extends paper.Item>(id: number): T | null => {
        const items = this.paper.project.getItems({
            id,
        })

        return items.length === 1 ? (items[0] as T) : null
    }

    removeItemWithPaperId = (id: number): boolean => {
        return this.paper.project
            .getItem({
                id,
            })
            .remove()
    }

    removeItemsWithOpeningGroupId = (id: number): void => {
        return this.paper.project
            .getItems({
                data: {
                    opening_group_id: id,
                },
            })
            .forEach((item) => item.remove())
    }

    removeItemsWithDrawableId = (id: number): void => {
        return this.paper.project
            .getItems({
                data: {
                    drawable_id: id,
                },
            })
            .forEach((item) => item.remove())
    }

    addPaperItem = (item: paper.Item): number => {
        return this.paper.project.activeLayer.addChild(item).id
    }

    // Generates a temporary point for calculations in this PaperScope
    generatePoint = (coordinates: Coordinate): paper.Point => {
        return new this.paper.Point(coordinates[0], coordinates[1])
    }

    getItemWithDrawableId = (id: number): paper.Item | null => {
        const items = this.paper.project.getItems({
            data: {
                drawable_id: id,
            },
        })
        // If more than one item is found,
        // then select the element itself and not the
        // label. The Item does not have any children as it is
        // not a group. Applies to joists and headers
        if (items.length > 1) {
            const itemsWithoutChildren = items.find((item) => item.data.shapeType && !item.children)
            return !!itemsWithoutChildren ? itemsWithoutChildren : null
        } else if (items.length === 1) {
            return items[0]
        } else {
            return null
        }
    }

    getItemWithOpeningLocationId = (id: number): paper.Item | null => {
        const items = this.paper.project.getItems({
            data: {
                opening_location_id: id,
            },
        })

        if (items.length) return items[0]
        return null
    }

    getItemsWithDrawableId = (id: number): paper.Item[] => {
        const items = this.paper.project.getItems({
            data: {
                drawable_id: id,
            },
        })

        return items
    }

    /**
     * Get a paperItem from the region id
     * @param id the region id
     * @returns paper item or null
     */
    getItemWithRegionId = (id: number): paper.Item | null => {
        const items = this.paper.project.getItems({
            data: {
                region_id: id,
            },
        })
        // If more than one item is found,
        // then select the element itself and not the
        // label.
        // The Item does not have any children as it is
        // not a group.
        // Applies to joists and headers
        if (items.length > 1) {
            const itemsWithoutChildren = items.find((item) => item.data.shapeType && !item.children)
            return !!itemsWithoutChildren ? itemsWithoutChildren : null
        } else if (items.length === 1) {
            return items[0]
        } else {
            return null
        }
    }

    /**
     *
     * @returns all region item by region_id
     */
    getRegionItemByRegionId = (region_id): paper.Item => {
        return this.paper.project.getItem({
            data: {
                region_id: region_id,
                shapeType: REGION_ENUMS.TYPE,
            },
        })
    }

    getAllRegionItems = (): paper.Item[] => {
        return this.paper.project.getItems({
            data: { shapeType: REGION_ENUMS.TYPE },
        })
    }

    getAllDrawableItems = (): paper.Item[] => {
        return this.paper.project.getItems({
            data: (data) => data?.drawable_id,
        })
    }

    getArrowElement = (): paper.Item => {
        return this.paper.project.getItem({
            data: (data) => data?.toolName === Arrow.NAME,
        })
    }

    getItemsWithCriteria = (key: string, criteria: (data: any) => boolean): paper.Item[] => {
        return this.paper.project.getItems({
            [key]: criteria,
        })
    }

    getPlanRaster = (): paper.Raster | null => {
        const rasters = this.getItemsWithCriteria('data', (data) => data.isMainRaster === true)

        if (rasters.length === 1) {
            return rasters[0] as paper.Raster
        } else {
            return null
        }
    }

    hideDrawablesWithCondition = (visibilityTest: (item: paper.Item) => boolean): void => {
        this.paper.project
            .getItems({
                data: function (data) {
                    return data?.drawable_id || data?.aiSuggestion?.id
                },
            })
            .forEach((item: paper.Item) => {
                item.visible = visibilityTest(item)
            })
    }

    showAllDrawables = (): void => {
        this.paper.project
            .getItems({
                data: (data) => data?.drawable_id,
            })
            .forEach((item: paper.Item) => {
                // By default when nothing is selected, everything except non area joists are shown
                item.visible = !isNonAreaJoistLine(item)
                item.selected = false
                if (item.fillColor) item.fillColor.alpha = item.data.originalOpacityValue
                if (item.strokeColor) item.strokeColor.alpha = item.data.originalOpacityValue
            })
    }

    activateTool = (name: string) => {
        this.mediator.mediate('tools', { activeTool: name })
    }

    setTooltip = ({ visible, title, color }: { visible: boolean; title: string; color: string }) => {
        this.mediator.mediate('common', {
            tooltip: { visible, title, color },
        })
    }

    toggleDrawable = (activeDrawableId: number, exiting?: boolean) => {
        this.mediator.mediate('2D', { activeDrawableId, exiting })
    }

    updateScaleForRegion = (paperId: number, newScale: string) => {
        const path = this.getItemWithPaperId(paperId)

        if (path?.data.scale !== undefined) {
            path.data.scale = newScale
            const labelItem = this.getItemWithPaperId<paper.TextItem>(path.data.labelId)
            if (labelItem) {
                labelItem.content = newScale
            }
        }
    }

    /**
     * Function returns stroke width based on Raster
     * Currently the 100 magic number comes from guess + check to ensure that aesthetics are consistent across plans with different resolutions
     * Plan resolution should be the primary variable in determining line width
     * @param raster
     * @returns
     */
    calculateStrokeWidthBasedOnRaster = (raster: paper.Raster): number => {
        return raster.width / 100
    }

    getHighestImageRasterIndex = () => {
        const rasters = this.paper.project.activeLayer.getItems({
            class: this.paper.Raster,
        })

        return Math.max(...rasters.map((raster) => raster.index))
    }

    sendItemToBackBeforeRaster = (item: paper.Item): void => {
        const highestImageIndex = this.getHighestImageRasterIndex()
        this.paper.project.activeLayer.insertChild(highestImageIndex + 1, item)
    }
}

export default Workspace
