import { createAction } from '@reduxjs/toolkit'
import { all, call, put, select, StrictEffect, takeEvery } from 'redux-saga/effects'
import { FETCH_OPENING_GROUPS_SUCCESS } from '../../../actions/drawable'
import { fetchProjectContextMarkups, updateDocumentChunkCalibrationFactor } from '../../../api/projects-api'
import { fetchOpeningGroupsByProjectId } from '../../../api/takeoff-api'
import { OpeningGroupAPI } from '../../../models/activeDrawable'
import { DocumentChunk } from '../../../models/documentChunk'
import { Region } from '../../../models/region'
import { isVertical } from '../../components/shared/CalibrationSetModal.component'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Color, Label, PathTool, Select, Workspace } from '../../lib/toolBoxes/2D'
import { preparedDrawablesSuccess, selectRegionId } from '../../slices/2D'
import { selectActiveDocumentChunk, updateSingleDocumentChunk } from '../../slices/documents'
import { selectProjectId } from '../../slices/project'
import {
    selectCalibrationLineCoords,
    selectLineOpacity,
    setCalibrationLineLength,
    setCalibrationRatio,
} from '../../slices/tools'
import { IMUP2DDrawableLocation, TOOL_TYPE_ENUMS } from '../../types'
import { convertOpeningGroupsToDrawableLocations } from './data-prep/convertOpeningsToDrawableLocations'
import { IToolObject } from '../../../models/tool'
import drawToolByType from './drawToolByType'

export const saveUpdatedCalibrationFactorAction = createAction<{
    ratio: number
    totalExpectedInches: number
    lengthInPixels: number
}>('saveUpdatedCalibrationFactor')

export type UpdateCalibrationInput = {
    xCalibrationLength?: number
    yCalibrationLength?: number
    xPixelLength?: number
    yPixelLength?: number
    xCalibrationFactor?: number
    yCalibrationFactor?: number
}

export function* regenerateMeasurementsByProjectAndDocumentIds(
    manager: PaperManager,
    projectId: number,
    activeDocumentChunkId: number
): Generator<StrictEffect, void, IToolObject[] & [Color, PathTool, Workspace, Label] & number & paper.Item[]> {
    const loadedToolObjects: IToolObject[] = yield call(fetchProjectContextMarkups, projectId)

    if (loadedToolObjects.length === 0) return

    const localLoadedMeasurementToolObjects = loadedToolObjects.filter((tool) => {
        return tool.type === TOOL_TYPE_ENUMS.MEASUREMENT && tool.document_chunk_id === activeDocumentChunkId
    })

    if (localLoadedMeasurementToolObjects.length > 0) {
        const [colorTool, pathTool, workspaceTool, labelTool]: [Color, PathTool, Workspace, Label] = yield call(
            manager.getTools,
            [Color.NAME, PathTool.NAME, Workspace.NAME, Label.NAME]
        )

        const lineOpacity: number = yield select(selectLineOpacity)

        const localMeasurementItems: paper.Item[] = yield call(
            workspaceTool.getItemsWithCriteria,
            'data',
            (data) =>
                data?.toolObject?.type === TOOL_TYPE_ENUMS.MEASUREMENT &&
                data?.toolObject?.document_chunk_id === activeDocumentChunkId
        )

        yield all(localMeasurementItems.map((item) => call(item.remove.bind(item))))

        yield all(
            localLoadedMeasurementToolObjects.map((measurement) =>
                call(drawToolByType, measurement, colorTool, pathTool, workspaceTool, lineOpacity, labelTool)
            )
        )
    }
}

export function* saveUpdatedCalibrationFactorEffect({
    payload,
}: ReturnType<typeof saveUpdatedCalibrationFactorAction>): Generator<
    StrictEffect,
    void,
    PaperManager &
        DocumentChunk &
        Array<OpeningGroupAPI> &
        number &
        Array<IMUP2DDrawableLocation> &
        Region['id'] &
        number[][]
> {
    try {
        const manager: PaperManager = yield call(managers.get2DManager)

        if (!manager) return

        const activeDocumentChunk: DocumentChunk | null = yield select(selectActiveDocumentChunk)
        const currentRegionId: Region['id'] | null = yield select(selectRegionId)
        const calibrationCoords: number[][] = yield select(selectCalibrationLineCoords)

        const isCalibrationVertical = isVertical(calibrationCoords[0][0], calibrationCoords[1][0])

        const apiInput: UpdateCalibrationInput = {
            xCalibrationFactor: isCalibrationVertical ? undefined : payload.ratio,
            yCalibrationFactor: isCalibrationVertical ? payload.ratio : undefined,
            xCalibrationLength: isCalibrationVertical ? undefined : payload.totalExpectedInches,
            yCalibrationLength: isCalibrationVertical ? payload.totalExpectedInches : undefined,
            xPixelLength: isCalibrationVertical ? undefined : payload.lengthInPixels,
            yPixelLength: isCalibrationVertical ? payload.lengthInPixels : undefined,
        }

        // Update the chunk in the database
        const updatedDocumentChunkResponse: DocumentChunk = yield call(
            updateDocumentChunkCalibrationFactor,
            activeDocumentChunk.id,
            currentRegionId,
            apiInput
        )

        if (!updatedDocumentChunkResponse) {
            throw new Error('Error: Error updating document chunk calibration factor')
        }

        const projectId: number = yield select(selectProjectId)

        if (!projectId) return

        yield call(regenerateMeasurementsByProjectAndDocumentIds, manager, projectId, activeDocumentChunk.id)

        // Fetch opening groups from api
        const openingGroups: Array<OpeningGroupAPI> = yield call(fetchOpeningGroupsByProjectId, projectId)

        // Original IMUP: pass api response to original IMUP reducer
        yield put({ type: FETCH_OPENING_GROUPS_SUCCESS, payload: openingGroups })

        // Convert opening groups to drawable locations with appropriate metadata
        const drawableLocations: Array<IMUP2DDrawableLocation> = yield call(
            convertOpeningGroupsToDrawableLocations,
            openingGroups
        )

        // Retrieve the unique set of document chunk IDs for all locations
        const documentChunkIds: Array<number> = [
            ...new Set(drawableLocations.map((location) => location.document_chunk_id)),
        ]

        // Provide drawable locations and unique document chunk IDs to the store
        yield put(preparedDrawablesSuccess({ drawableLocations, documentChunkIds }))

        // Update the chunk in the store
        yield put(updateSingleDocumentChunk(updatedDocumentChunkResponse))

        yield put(setCalibrationRatio(payload.ratio))
        yield put(setCalibrationLineLength(null))

        yield call(manager.useTool, Select.NAME)
    } catch (error) {
        yield call(console.error, (error as any).message)
    }
}

export function* watchForSaveUpdatedCalibrationFactor() {
    yield takeEvery(saveUpdatedCalibrationFactorAction.type, saveUpdatedCalibrationFactorEffect)
}
