import { useEffect } from 'react'
import { fetchAllProjectData } from '../imup/sagas/2D/data-prep/fetchAllProjectData'
import { Project } from '../models/project'
import { getProject } from '../reducers/drawable'
import { useAppDispatch } from './useAppDispatch'
import { useAppSelector } from './useAppSelector'

export function useProjectDataFetch(): void {
    const dispatch = useAppDispatch()
    const project: Project = useAppSelector((state) => getProject(state.drawable))

    useEffect(() => {
        if (project && project.id) {
            dispatch(fetchAllProjectData(project))
        }
    }, [dispatch, project])
}
