import { createAction } from '@reduxjs/toolkit'
import isNull from 'lodash/isNull'
import { all, call, put, select, takeEvery } from 'redux-saga/effects'
import { IMaterialModificationConflict } from '../../../models/masterSetPlan'
import { DRAWABLE_TYPES, DRAWING_TYPES } from '../../../shared/constants/drawable-types'
import managers from '../../lib/managers'
import PaperManager from '../../lib/managers/PaperManager'
import { Overlay, Workspace } from '../../lib/toolBoxes/2D'
import { setConflictedMaterialOptionModifications } from '../../slices/masterSetPlan'
import { IMUP2DDrawableLocation } from '../../types'
import drawConflictByType from './drawConflictByType'
import { RedrawShapeStoreState, selectRedrawState } from './handleUpdateOpeningGroupsSuccess'
import { selectMarkupState } from './markupDocument'

export const highlightOptionConflictsAction = createAction<{ overlayPaperRasterId: number }>('highlightOptionConflicts')

export function* handleHighlightOptionConflicts(action: ReturnType<typeof highlightOptionConflictsAction>) {
    const manager: PaperManager | null = yield call(managers.get2DManager)

    if (isNull(manager) || action.payload === null) return

    const [overlayTool, workspaceTool]: [Overlay, Workspace] = yield call(manager.getTools, [
        Overlay.NAME,
        Workspace.NAME,
    ])
    const { areaOpacity, lineOpacity }: RedrawShapeStoreState = yield select(selectRedrawState)

    const { activeFloor, drawableLocations }: ReturnType<typeof selectMarkupState> = yield select(selectMarkupState)

    const allDrawableLocationOnDocument: IMUP2DDrawableLocation[] = yield drawableLocations.filter(
        ({ document_chunk_id, drawing_type }) =>
            document_chunk_id === activeFloor.document_chunk.id &&
            drawing_type !== DRAWABLE_TYPES.NOTE &&
            drawing_type !== DRAWABLE_TYPES.HIGHLIGHTER
    )

    // collect all overlapped Locations
    const overlappedDrawableLocation: IMUP2DDrawableLocation[] = allDrawableLocationOnDocument.filter((location) => {
        const item = workspaceTool.getItemWithOpeningLocationId(location.opening_location_id)
        return item && overlayTool.isPathInsideOverlay(item, action.payload.overlayPaperRasterId)
    })

    //hide  all already Drawn items before
    const alreadyDrawnItemsToHide: paper.Item[] = yield call(
        workspaceTool.getItemsWithCriteria,
        'data',
        (data) => data?.drawable_id || data?.aiSuggestion?.id
    )

    alreadyDrawnItemsToHide.forEach((item) => {
        item.visible = false
    })

    const regionPaths: paper.Path[] = workspaceTool.getAllRegionItems() as paper.Path[]

    const materialModificationConflicts: IMaterialModificationConflict[] = overlappedDrawableLocation
        // sorting locations that AREAS will be drawn first
        .sort((a, b) => {
            if (a.shapeType === DRAWING_TYPES.AREA && b.shapeType !== DRAWING_TYPES.AREA) {
                return -1
            }
            if (a.shapeType !== DRAWING_TYPES.AREA && b.shapeType === DRAWING_TYPES.AREA) {
                return 1
            }
            return 0
        })
        .map((location) => {
            return {
                id: Number(location.opening_location_id + '0000'), // temporary ID
                material_location_id: location.opening_location_id,
                coordinates: location.coordinates,
                floor_plan_id: location.document_chunk_id,
                option_ids: [action.payload.overlayPaperRasterId],
            }
        })

    // draw conflicts
    yield all(
        materialModificationConflicts.map((materialConflict) => {
            return call(drawConflictByType, materialConflict, areaOpacity, lineOpacity, regionPaths)
        })
    )

    yield put(setConflictedMaterialOptionModifications(materialModificationConflicts))
}

export function* watchForHighlightOptionConflicts() {
    yield takeEvery(highlightOptionConflictsAction.type, handleHighlightOptionConflicts)
}
